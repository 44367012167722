.addform-container {
  width: 60vw;
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1%;
  background-color: #eeeeee;
  border-radius: 5px;
}

.super {
  color: red;
}

.leftwrapper,
.rightwrapper {
  width: 50%;
}

.itemdetails,
.purchasestatus,
.bottomwrapper {
  width: 100%;
}

.itemdetails > div,
.purchasestatus > div,
.taxdetails > div,
.vendorpayment > div,
.otherdetails > div {
  display: flex;
  flex-direction: row;

  width: 100%;
}

.itemdetails > div div:nth-child(odd),
.purchasestatus > div div:nth-child(odd),
.taxdetails > div div:nth-child(odd),
.vendorpayment > div div:nth-child(odd),
.otherdetails > div div:nth-child(odd) {
  width: 45%;
  text-align: left;
  padding: 0;
  font-weight: 400;
  word-wrap: break-word;
}

.itemdetails > div div:nth-child(even),
.purchasestatus > div div:nth-child(even),
.taxdetails > div div:nth-child(even),
.vendorpayment > div div:nth-child(even),
.otherdetails > div div:nth-child(even) {
  width: 55%;
  text-align: left;
  padding: 0.8% 0;
}
.itemdetails > div div:nth-child(even) input,
.purchasestatus > div div:nth-child(even) input,
.taxdetails > div div:nth-child(even) input,
.vendorpayment > div div:nth-child(even) input,
.otherdetails > div div:nth-child(even) input {
  width: 80%;
  border-style: none;
  border: 1px solid black;
}

.dropdown {
  width: 80%;
  border-left: 1px solid black;
}

.itemdetails > div:nth-child(1),
.purchasestatus > div:nth-child(1),
.taxdetails > div:nth-child(1),
.vendorpayment > div:nth-child(1),
.otherdetails > div:nth-child(1) {
  justify-content: center;
  font-weight: 400;
  color: white;
  background-color: #ee4949;
}

.bottomwrapper > div:nth-child(1) {
  margin-top: 10px;
}

.bottomwrapper > div:nth-child(2) input {
  width: 8%;
  text-align: center;
}

.bottomwrapper .buttonswrap {
  display: flex;
  flex-direction: row;
}

.bottomwrapper .buttonswrap div:nth-child(1) {
  width: 70%;
  padding-left: 46%;
}

.bottomwrapper .buttonswrap div:nth-child(2) {
  width: 30%;
  padding: 0 1% 0 10%;
}

.bottomwrapper .buttonswrap div button {
  margin: 0;
  cursor: pointer;
}
.bottomwrapper .buttonswrap div button:hover {
  border: 1px solid #e4e4e4;
}

.bottomwrapper .finalmetric {
  margin: 10px 0 10px 0;
}

.plusminusicons {
  cursor: pointer;
  width: 22px;
  height: 22px;
}
