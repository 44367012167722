@import url("https://fonts.googleapis.com/css?family=Audiowide");

.container {
  height: 100vh;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 1.2rem 0 1.2rem;
  font-family: sans-serif;
}

.color {
  color: #ee4949;
}

.container-inner {
  background-color: #fff;
  width: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.4rem;
  padding: 1rem 0 2rem 0;
}

.container-inner h1,
p,
form {
  /* width: 24rem; */
  width: 96%;
  margin: 0em;
  text-align: center;
}

.container-inner p {
  font-size: 1.4rem;
}

.container-inner h1 {
  font-size: 2.6rem;
  font-family: "Audiowide";
  color: #ee4949;
}

.container-inner form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.container-inner input,
button {
  width: 100%;
  height: 3rem;
  border-radius: 4px;
}

.container-inner input {
  border: 0.1px solid #e6e3e3;
  outline-color: #ee4949;
  background-color: #f1f1f1;
}

.container-inner button {
  color: #fff;
  background-color: #ee4949;
  font-size: 1.4rem;
  border: none;
  cursor: pointer;
}

.container-inner button:hover {
  background-color: #f78484;
}

.container-inner img {
  width: 35%;
  height: 100%;
  /* position: absolute;
  top: 50%;
  transform: translate(-100%, -50%); */
}

.h1-div {
  position: relative;
  width: 100%;
  text-align: center;
  height: 50px;
}

.eye-div {
  position: relative;
}

.open-eye {
  position: absolute;
  width: 5%;
  top: 50%;
  transform: translate(-120%, -50%);

  cursor: pointer;
}

.close-eye {
  position: absolute;

  width: 5%;
  top: 50%;
  transform: translate(-120%, -50%);
  cursor: pointer;
}
