.resultpopup-wrapper {
}

.row {
  cursor: pointer;
}

.row:hover {
  background-color: lightgray;
}
