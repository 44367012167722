@import "../../../../../customer-app/common-css/Lato-font.css";
@import "../../../../../customer-app/common-css/Poppins-font.css";

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --font-color1: #777777;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
  --border-color1: #ddd;
  --textbox-bg-color: #f9f9f9;
  --header-bg-color1: #333333;
  --header-bg-color2: #002933;
  --header-bg-color3: #36304a;
}

.livestock-table-wrapper {
  font-family: "Poppins", sans-serif;
  padding: 0 2px 0 3px;
  height: 85%;
}

.livestock-table-wrapper .tab {
  width: 100%;
  height: 5%;
  margin-bottom: 1%;

  display: flex;
}

.livestock-table-wrapper .tab > div {
  border: 1px solid #9b59b6;
  display: flex;
  width: 14%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 1em;
}

.rowswrapper {
  overflow-y: scroll;
  height: 85%;
}

.tablehead,
.tablerow {
  display: flex;
  flex-direction: row;
  font-size: 10px;
  min-height: 4vh;
}

.tablehead {
  font-weight: 600;
  word-wrap: break-word;
  background-color: var(--header-bg-color1);
  color: white;
  min-height: 5vh;
}

.tablerow {
  font-weight: 500;
}

.tablehead > span,
.tablerow > span {
  align-self: center;
}

.tablerow > span:nth-last-child(3),
.tablerow > span:nth-child(3) {
  font-weight: 700;
}

.tablerow:nth-child(odd) {
  background-color: #0000000d;
}

.editspan,
.deletespan {
  text-align: center;
  cursor: pointer;
}

.deleteicon {
  width: 25%;
  height: 25%;
}

.editicon {
  width: 60%;
  height: 60%;
}
