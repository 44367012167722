.error {
  position: absolute;
  right: 3%;
  top: 16%;
}

.error svg {
  height: 1rem;
  width: 1rem;
  padding-bottom: 0px;
  z-index: -3px;
}

.error div {
  padding: 0px;

  box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
