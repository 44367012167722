.size {
  width: 60px;
  height: 60px;
  stroke-width: 10px;
}

@media only screen and (max-width: 500px) {
  .size {
    width: 50px;
    height: 50px;
    stroke-width: 15px;
  }
}
