@import "../../../customer-app/common-css/Poppins-font.css";

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --font-color1: #777777;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
  --border-color1: #ddd;
  --textbox-bg-color: #f9f9f9;
  --header-bg-color1: #333333;
  --header-bg-color2: #002933;
  --header-bg-color3: #36304a;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 400;
  background-color: rgba(0, 0, 0, 0.75);
}

.confirmmodal {
  position: fixed;
  top: 7vh;
  left: 0;
  width: 100%;
  background-color: transparent;
  z-index: 401;
  animation: slide-down 300ms ease-out forwards;
  font-family: "Poppins", sans-serif;
}

.confirmmodal .content {
  border-radius: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 28%;
  padding: 1rem;
  line-height: 2.8;
  position: relative;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  font-weight: 500;
  font-size: 1.2em;
}

.confirmmodal .content .button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 3%;
}

.confirmmodal .content .button-wrapper button {
  width: 10rem;
  height: 40px;
  margin: 0;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--theme-color-shade1);
}

.confirmmodal .closebutton {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--font-color);
  cursor: pointer;
  width: 20%;
  height: 20%;
}

.confirmmodal .button-wrapper button:nth-child(1):hover {
  background-color: transparent;
  color: var(--theme-color);
  border: 1px solid var(--theme-color);
}

@media (min-width: 768px) {
  /* .confirmmodal {
    width: 40rem;
    left: calc(50% - 20rem);
  } */
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
