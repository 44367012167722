@import "../../../../customer-app/common-css/Lato-font.css";
@import "../../../../customer-app/common-css/Poppins-font.css";

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --header-color2: #d8e3f4;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}

.main-div-stocklist {
  font-family: "Lato", sans-serif;
  height: 88%;
  overflow: auto;
  width: 100%;
}

.tableHeader,
.tableData {
  display: flex;
  font-size: 0.9em;
  width: 100%;
  text-align: center;
}

.tableHeader {
  font-weight: 800;
  color: white;
  position: sticky;
  top: 0;
}

.tableData {
  font-weight: 500;
  color: var(--font-color);
}

.tableHeader div {
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  background-color: #bee6ec;
  /* #ddf0f6; */
  color: black;
  padding: 6px 0 6px 0;
}

.tableData div {
  color: black;
  padding: 8px 0 8px 0;
  /* border-left: 1px solid white; */
  border-bottom: 1px solid gray;
  background-color: #ddf0f6;
}
.tableHeader div > div,
.tableData div > div {
  border-bottom: none;
}
.main-div-stocklist > div:nth-child(even) {
  background-color: #e4e4e4;
}

.tableData div:nth-child(7),
.tableData div:nth-child(8),
.tableData div:nth-child(9) {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0;
  padding: 0;
}
.tableData div:nth-child(7) div:nth-child(1) {
  width: 30%;
  border-left: none;
}

.tableData div:nth-child(7) div:nth-child(2),
.tableData div:nth-child(7) div:nth-child(4) {
  width: 22%;
}
.tableData div:nth-child(7) div:nth-child(4) {
  border-right: none;
}
.tableData div:nth-child(7) div:nth-child(3) {
  width: 26%;
}

.tableData div:nth-child(8) div:nth-child(1),
.tableData div:nth-child(8) div:nth-child(3),
.tableData div:nth-child(9) div:nth-child(1),
.tableData div:nth-child(9) div:nth-child(3) {
  width: 30%;
}
.tableData div:nth-child(8) div:nth-child(2),
.tableData div:nth-child(9) div:nth-child(2) {
  width: 40%;
}

.tableData div:nth-child(6) {
  cursor: pointer;
}

.tableData div:nth-child(13) {
  cursor: pointer;
}

.tableData div:nth-child(14) {
  cursor: pointer;
}

.modalbutton {
  cursor: pointer;
}

.accrodiandiv {
  font-size: 1.1em;
  font-weight: 600;
  width: 60%;
  margin: auto;
}

.accrodiandiv .header {
  display: flex;
}

.accrodiandiv .header div {
  background-color: #161f6a;
  color: white;
  font-size: 0.8em;
}

.accrodiandiv .data {
  display: flex;
}

.checkbox {
  cursor: pointer;
}
.update:hover {
  border: 1px solid lightgray;
  background-color: yellow;
}

.tab {
  display: flex;
  flex-direction: row;
}
.normalbuttonstyle {
  border-radius: 0;
  margin: 0;
  cursor: pointer;
  width: 10%;
  background-color: transparent;
  color: var(--font-color);
  font-size: 14px;
}

.buttonclickstyle {
  border: 1px solid black;
  background-color: var(--header-color2);
  color: black;
  font-weight: 600;
  border-top: none;
}
