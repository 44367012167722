@import "../common-css/Lato-font.css";

.backdrop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0.5;
  width: 100%;
  height: 100vh;
  z-index: 30;
  left: 0;
}

#navigation-menus-wrapper-background-div {
  position: fixed;
  top: 0;
  height: 100%;
  width: 300px;
  display: inline-block;
  background-color: #ffffff;
  z-index: 32;
  transition-property: left;
  transition: 800ms;
  /* animation: slide-right 400ms ease-out forwards; */
}

.navigation-menus-wrapper-ul {
  left: 0;
  list-style-type: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  min-width: 18vw;
}

.navigation-menus-wrapper-ul li {
  padding-left: 60px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: large;
  margin-bottom: 30px;
}

.user-image-name-li {
  height: 6em;
  background-color: rgb(247, 247, 247);
  display: block;
  position: relative;
}

.navigation-menus-cross {
  cursor: pointer;
  height: 28px;
  width: 28px;
  position: absolute;
  right: 0;
  top: 0;
  display: block;
}

.profile-pic {
  width: 3em;
  height: 3em;
  margin-top: 0px;
}

.profile-pic-div {
  height: 100%;
  padding-top: 20px;
}

.user-image-name-li-div {
  height: 100%;
}

.user-image-name-li-div div {
  display: inline-block;
  overflow: auto;
}

.name-mob-div {
  height: 100%;
  padding-top: 28px;
  padding-left: 12px;
}

.name-mob-div .mobile {
  font-size: 15px;
  color: red;
  font-weight: 700;
}
.name-mob-div p {
  margin: 0;
}

@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateX(-30rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
} ;
