.wrapper {
  font-family: "Lato", sans-serif;
  overflow: auto;
  height: 100%;
  background-color: #d9e2e1;
  position: relative;
  width: 100%;
}

.leftarrow,
.rightarrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 76px;
  height: 80px;
  background-color: #f3f3f3;
  opacity: 0.7;
  cursor: pointer;
}

.leftarrow {
  left: 0;
}
.rightarrow {
  right: 0;

  /* transition: 4000s ease-in-out; */
}

.square-wrapper {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);

  width: 8%;
  /* display: flex; */
  justify-content: space-evenly;
}

.image-square,
.image-square-select {
  box-shadow: 1px 1px 1px #ddd;
  stroke: lightgray;
  stroke-width: 3px;
  color: #fff;
  height: 100%;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.image-square-select {
  color: #ee4949;
}

@media only screen and (max-width: 500px) {
  .leftarrow,
  .rightarrow {
    width: 30px;
    height: 30px;
    z-index: 1;
  }
}
