@import "../../../../../customer-app/common-css/Lato-font.css";
@import "../../../../../customer-app/common-css/Poppins-font.css";

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}
.main-div-stocklist {
  font-family: "Lato", sans-serif;
  height: 88%;
  overflow: auto;
  width: 100%;
}
.main-div-stocklist thead tr th,
.main-div-stocklist tbody tr td {
  font-size: 0.9em;
  width: 100%;
  text-align: center;
}

.main-div-stocklist thead tr th {
  font-weight: 800;
  color: white;
}

.main-div-stocklist tbody tr td {
  font-weight: 500;
  color: var(--font-color);
}
.main-div-stocklist .edit,
.main-div-stocklist .delete,
.main-div-stocklist .status {
  width: 2.3em;

  font-size: small;
  border-radius: 4px;
  border-style: none;
  background-color: lightseagreen;
}

.main-div-stocklist .delete {
  width: 3em;
}
.main-div-stocklist .status {
  width: 6em;
}
.pending {
  color: red;
  font-weight: bold;
}

.none {
  font-weight: bold;
}

.complete {
  color: blueviolet;
  font-weight: bolder;
}

.purchaseinfo {
  display: flex;
  flex-direction: row;
}

.purchaseinfo > div {
  width: 40%;
  font-weight: 600;
}

.purchaseinfo > div span {
  display: inline-block;
}

.purchaseinfo > div > div span:nth-child(1) {
  color: red;
  text-align: right;
  width: 30%;
}
.purchaseinfo > div > div span:nth-child(2) {
  color: black;
  padding-left: 10px;
  width: 20%;
}
