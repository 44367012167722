@import url(https://fonts.googleapis.com/css?family=Audiowide);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gowun+Batang:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,700;1,900&display=swap);
.Login_container__3bNBW {
  height: 100vh;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 1.2rem 0 1.2rem;
  font-family: sans-serif;
}

.Login_color__QIKtf {
  color: #ee4949;
}

.Login_container-inner__2fRUG {
  background-color: #fff;
  width: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 1.4rem;
  gap: 1.4rem;
  padding: 1rem 0 2rem 0;
}

.Login_container-inner__2fRUG h1,
p,
form {
  /* width: 24rem; */
  width: 96%;
  margin: 0em;
  text-align: center;
}

.Login_container-inner__2fRUG p {
  font-size: 1.4rem;
}

.Login_container-inner__2fRUG h1 {
  font-size: 2.6rem;
  font-family: "Audiowide";
  color: #ee4949;
}

.Login_container-inner__2fRUG form {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.Login_container-inner__2fRUG input,
button {
  width: 100%;
  height: 3rem;
  border-radius: 4px;
}

.Login_container-inner__2fRUG input {
  border: 0.1px solid #e6e3e3;
  outline-color: #ee4949;
  background-color: #f1f1f1;
}

.Login_container-inner__2fRUG button {
  color: #fff;
  background-color: #ee4949;
  font-size: 1.4rem;
  border: none;
  cursor: pointer;
}

.Login_container-inner__2fRUG button:hover {
  background-color: #f78484;
}

.Login_container-inner__2fRUG img {
  width: 35%;
  height: 100%;
  /* position: absolute;
  top: 50%;
  transform: translate(-100%, -50%); */
}

.Login_h1-div__2hj6A {
  position: relative;
  width: 100%;
  text-align: center;
  height: 50px;
}

.Login_eye-div__OSzJA {
  position: relative;
}

.Login_open-eye__aLUlf {
  position: absolute;
  width: 5%;
  top: 50%;
  -webkit-transform: translate(-120%, -50%);
          transform: translate(-120%, -50%);

  cursor: pointer;
}

.Login_close-eye__3WaDG {
  position: absolute;

  width: 5%;
  top: 50%;
  -webkit-transform: translate(-120%, -50%);
          transform: translate(-120%, -50%);
  cursor: pointer;
}





:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}

.CustomerDetail_wrapper__2gW4y {
  /* border: 2px solid lightgray; */
  height: 100%;
  display: flex;
  font-family: "Lato", sans-serif;
  box-sizing: border-box;
}

.CustomerDetail_customerinfo__3buCR {
  width: 64%;
  height: 100%;
  padding: 0.5% 2% 0 2%;
}

.CustomerDetail_invoiceinfo__i_YZO {
  width: 12%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5% 2% 0 0;
  margin: 0;
}

.CustomerDetail_otherdetails__33HZg {
  width: 24%;
  height: 100%;
  border: 1px solid #e8e8e8;
  border: 1px solid var(--border-color);
  padding: 0.5% 0;
  font-size: 0.9em;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.CustomerDetail_otherdetails__33HZg > div {
  display: flex;
  background-color: #fff;
}

.CustomerDetail_otherdetails__33HZg > div > div:nth-child(1) {
  width: 65%;
  text-align: left;
  color: #77798c;
  color: var(--font-color);
  font-weight: 600;
}

.CustomerDetail_otherdetails__33HZg > div > div:nth-child(2) {
  width: 35%;
  padding-left: 5px;
}
.CustomerDetail_otherdetails__33HZg > div:nth-child(2) > div:nth-child(2) {
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.CustomerDetail_invoiceinfo__i_YZO input[type="search"],
.CustomerDetail_customerinfo__3buCR input[type="search"] {
  width: 100%;
  height: 40px;
  margin: 0;
  color: #77798c;
  color: var(--font-color);
  font-size: 0.9em;
}

.CustomerDetail_invoicesearch-wrapper__3MayM input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: 14px;
  cursor: pointer;
}

.CustomerDetail_invoiceNo__3KWJz input[type="number"],
.CustomerDetail_date__2fYU4 input[type="date"] {
  width: 100%;
}

.CustomerDetail_searchcustomer-wrapper__BG6Xv {
  margin-bottom: 1%;
  padding: 0 1%;
  display: flex;
}

.CustomerDetail_searchcustomer-wrapper__BG6Xv .CustomerDetail_userId__1lmqO {
  width: 33%;
  color: #77798c;
  color: var(--font-color);
  font-weight: 600;
  display: flex;
  align-items: center;
}

.CustomerDetail_searchcustomer-wrapper__BG6Xv .CustomerDetail_searchoption__2vkyr {
  display: flex;
  color: #161f6a;
  color: var(--bluish-font-color);
  font-weight: 500;
  width: 25%;
}

.CustomerDetail_searchcustomer-wrapper__BG6Xv .CustomerDetail_searchoption__2vkyr div {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ddd;
}

.CustomerDetail_searchcustomer-wrapper__BG6Xv .CustomerDetail_searchcustomer__93Gvc {
  width: 42%;
}

.CustomerDetail_backgroundcolor__3cuXE {
  color: #fff;
  color: var(--white-color);
  background-color: #ee4949;
  background-color: var(--theme-color);
  border: groove;
}

.CustomerDetail_searchcustomer-wrapper__BG6Xv .CustomerDetail_userId__1lmqO > span,
.CustomerDetail_invoiceNo__3KWJz > span,
.CustomerDetail_date__2fYU4 > span,
.CustomerDetail_customerName__NNHXF > span,
.CustomerDetail_address1__2vMvA > span,
.CustomerDetail_address2__YHJ6M > span,
.CustomerDetail_mobile__ntuNt > span,
.CustomerDetail_email__1OLi8 > span,
.CustomerDetail_city__3mIRh > span,
.CustomerDetail_state__3K2Yg > span,
.CustomerDetail_role__2Ukk6 > span {
  font-weight: 600;
  color: #161f6a;
  color: var(--bluish-font-color);
}
.CustomerDetail_date__2fYU4 {
  display: flex;
  flex-direction: column;
  /* margin-top: 3%; */
}

.CustomerDetail_date__2fYU4 input[type="date"] {
  cursor: pointer;
}

.CustomerDetail_customerName__NNHXF,
.CustomerDetail_mobile__ntuNt,
.CustomerDetail_email__1OLi8,
.CustomerDetail_address1__2vMvA,
.CustomerDetail_address2__YHJ6M,
.CustomerDetail_city__3mIRh,
.CustomerDetail_state__3K2Yg,
.CustomerDetail_role__2Ukk6,
.CustomerDetail_customerinfo__3buCR {
  display: flex;
  flex-direction: column;
}

.CustomerDetail_customerName__NNHXF,
.CustomerDetail_mobile__ntuNt,
.CustomerDetail_email__1OLi8,
.CustomerDetail_address1__2vMvA,
.CustomerDetail_address2__YHJ6M,
.CustomerDetail_city__3mIRh,
.CustomerDetail_state__3K2Yg,
.CustomerDetail_role__2Ukk6,
.CustomerDetail_invoiceNo__3KWJz {
  margin-bottom: 6%;
}

.CustomerDetail_invoicesearch-wrapper__3MayM {
  margin-bottom: 4%;
}

.CustomerDetail_name-address-wrapper__v9Vhx {
  display: flex;
  width: 100%;
  height: 100%;
  text-align: left;
  position: relative;
}

/* .name-address-wrapper .customerName {
  width: 100%;
} */

.CustomerDetail_name-wrapper__3hRsD,
.CustomerDetail_address-wrapper__2zABg {
  width: 23%;
  padding: 0 1%;
}

.CustomerDetail_mob-email-wrapper__23EEi,
.CustomerDetail_city-state-wrapper__3hy2M {
  width: 20%;
  padding: 0 1%;
}

.CustomerDetail_role-wrapper__3cPYC {
  width: 20%;
  padding: 0 1%;
}

.CustomerDetail_customerName__NNHXF input,
.CustomerDetail_mobile__ntuNt input,
.CustomerDetail_email__1OLi8 input,
.CustomerDetail_address1__2vMvA input,
.CustomerDetail_address2__YHJ6M input,
.CustomerDetail_city__3mIRh input,
.CustomerDetail_state__3K2Yg input,
.CustomerDetail_role__2Ukk6 .CustomerDetail_rolelist__7cCDZ,
.CustomerDetail_date__2fYU4 .CustomerDetail_datelist__JoNv5,
.CustomerDetail_invoiceNo__3KWJz input,
.CustomerDetail_invoiceinfo__i_YZO input[type="search"],
.CustomerDetail_customerinfo__3buCR input[type="search"] {
  height: 34px;
  width: 100%;
  margin-top: 0;
}

.CustomerDetail_role__2Ukk6 .CustomerDetail_rolelist__7cCDZ {
  color: white;
  background-color: #116d6e;
  font-weight: 600;
}

.CustomerDetail_customerName__NNHXF input,
.CustomerDetail_mobile__ntuNt input,
.CustomerDetail_email__1OLi8 input,
.CustomerDetail_address1__2vMvA input,
.CustomerDetail_address2__YHJ6M input,
.CustomerDetail_city__3mIRh input,
.CustomerDetail_state__3K2Yg input {
  font-size: 1em;
}

.CustomerDetail_name-address-wrapper__v9Vhx .CustomerDetail_addbuttonwrapper__1_AYG {
  align-self: flex-end;
  width: 99%;
  position: absolute;
  text-align: right;
}
.CustomerDetail_name-address-wrapper__v9Vhx .CustomerDetail_addbuttonwrapper__1_AYG .CustomerDetail_addcustomer__2Wkx6,
.CustomerDetail_name-address-wrapper__v9Vhx .CustomerDetail_addbuttonwrapper__1_AYG .CustomerDetail_resetCustomer__147YW {
  height: 1.4em;
  width: 20%;
  cursor: pointer;
  color: #ee4949;
  border: 1px solid #f48a8a;
  background-color: transparent;
}

.CustomerDetail_name-address-wrapper__v9Vhx .CustomerDetail_addbuttonwrapper__1_AYG .CustomerDetail_resetCustomer__147YW {
  width: 12%;
  margin-left: 4%;
}

.CustomerDetail_name-address-wrapper__v9Vhx .CustomerDetail_addbuttonwrapper__1_AYG .CustomerDetail_addcustomer__2Wkx6:hover,
.CustomerDetail_name-address-wrapper__v9Vhx .CustomerDetail_addbuttonwrapper__1_AYG .CustomerDetail_resetCustomer__147YW:hover {
  color: white;
  background-color: #ee4949;
}

:root {
  --theme-color: #ee4949;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
}

.Textbox_textbox__2PWUA {
  width: 100%;
  height: 46px;
  margin-top: 1%;
  margin-bottom: 4%;
  border-radius: 4px;
  border-style: none;
  background-color: #f9f9f9;
  border: 0.4px solid #ddd;
  font-weight: 400;
  font-size: 17px;
  color: #77798c;
  color: var(--font-color);
  padding-left: 1%;
}

.Textbox_textbox__2PWUA:focus {
  border-width: 0.5px;
  outline-color: #ee4949;
}

.MessageModal_backdrop__2BBA_ {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1200;
  background-color: rgba(0, 0, 0, 0.75);
}

.MessageModal_modal__1OW82 {
  position: fixed;
  top: 20vh;
  left: 5%;
  width: 90%;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 1210;
  -webkit-animation: MessageModal_slide-down__1BQqA 300ms ease-out forwards;
          animation: MessageModal_slide-down__1BQqA 300ms ease-out forwards;
}

.MessageModal_modal__1OW82 > div:nth-child(2) {
  text-align: right;
}
.MessageModal_modal__1OW82 button {
  width: 20%;

  cursor: pointer;
  border-radius: 50px;
}

.MessageModal_modal__1OW82 .MessageModal_content__3R_uz {
  font-weight: 600;
  font-size: 1.2em;
}

@media (min-width: 768px) {
  .MessageModal_modal__1OW82 {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@-webkit-keyframes MessageModal_slide-down__1BQqA {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes MessageModal_slide-down__1BQqA {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.LoaderSpinnerKit_spinnerContainer__3o9nU {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.82);
  /* display: flex; */
  /* align-items: center; */
  z-index: 9999;
}

.LoaderSpinnerKit_spinner__eNxSF {
  z-index: 10000;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);

  /* border: 1px solid rgb(55, 53, 53); */
}

.LoaderSpinnerKit_tag__mp20T {
  text-align: center;
  font-size: 2rem;
  color: green;
}

.BillCreation_wrapper__2gq7e {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1%;
  padding-bottom: 1%;
}

.BillCreation_customer-wrapper__dkA2W {
  width: 100%;
  height: 32%;
  background-color: #fff;
}

.BillCreation_prod-actionmenus-wrapper__WC65V {
  width: 100%;
  height: 68%;
  display: flex;
  padding-top: 0.8%;
}

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}

.ActionMenus_wrapper__vEqlW {
  height: 100%;
  width: 16%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #f9f9f9;
  font-weight: 600;
}

.ActionMenus_wrapper__vEqlW > div {
  display: flex;
  flex-wrap: wrap;
  /* height: 33.33%; */
  cursor: pointer;
}
.ActionMenus_wrapper__vEqlW > div:nth-child(1) {
  padding: 2% 0 0 2%;
  height: 20%;
}

.ActionMenus_wrapper__vEqlW > div:nth-child(2) {
  padding: 2% 0 0 2%;
  /* margin-top: 1.665%; */
  height: 37.5%;
}

.ActionMenus_wrapper__vEqlW > div:nth-child(3) {
  padding: 2% 0 0 2%;
  /* margin-top: 1.665%; */
  height: 37.5%;
}

.ActionMenus_wrapper__vEqlW > div:nth-child(1) > div,
.ActionMenus_wrapper__vEqlW > div:nth-child(2) > div,
.ActionMenus_wrapper__vEqlW > div:nth-child(3) > div {
  width: 49%;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #187498;
  color: #fff;
  color: var(--white-color);
}

.ActionMenus_wrapper__vEqlW > div:nth-child(1) > div:hover,
.ActionMenus_wrapper__vEqlW > div:nth-child(2) > div:hover,
.ActionMenus_wrapper__vEqlW > div:nth-child(3) > div:hover {
  background-color: #f05c5c;
  background-color: var(--theme-color-shade1);
  border: 1px solid #f05c5c;
  border: 1px solid var(--theme-color-shade1);
}

/* .wrapper > div:nth-child(1) > div:nth-child(1) {
  margin-right: 1%;
} */

.ActionMenus_wrapper__vEqlW > div:nth-child(2) > div {
  height: 33.33%;
}

.ActionMenus_wrapper__vEqlW > div:nth-child(3) > div {
  height: 33.33%;
}

/* .wrapper > div:nth-child(3) > div:nth-child(1),
.wrapper > div:nth-child(3) > div:nth-child(3),
.wrapper > div:nth-child(3) > div:nth-child(5),
.wrapper > div:nth-child(2) > div:nth-child(1),
.wrapper > div:nth-child(2) > div:nth-child(3),
.wrapper > div:nth-child(2) > div:nth-child(5) {
  margin-right: 1%;
} */

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}

.PrintDocument_wrapper__3O8ZP {
  display: flex;
  flex-direction: column;
  /* position: fixed;
  top: 2%;
  left: 2%; */
  padding: 1% 1.5% 0 1.5%;
  font-family: "Lato", sans-serif;
  width: 30%;
  line-height: 1.5;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.PrintDocument_wrapper__3O8ZP > p {
  text-align: center;
  margin: 0;
  font-size: 10px;
}

.PrintDocument_wrapper__3O8ZP > span:nth-child(2),
.PrintDocument_wrapper__3O8ZP > span:nth-child(3),
.PrintDocument_wrapper__3O8ZP > span:nth-child(4) {
  text-align: center;
  font-size: 0.8em;
  font-weight: 600;
  color: #77798c;
  color: var(--font-color);
}

.PrintDocument_wrapper__3O8ZP > span:nth-child(4) {
  font-size: 1em;
  color: #161f6a;
  color: var(--bluish-font-color);
}

.PrintDocument_wrapper__3O8ZP .PrintDocument_customerinfo-wrapper__3374Z {
  display: flex;
  flex-direction: row;
  width: 100%;

  background-color: #fff;
  font-weight: 300;
  font-size: 10px;
}

.PrintDocument_customerinfo-wrapper__3374Z .PrintDocument_customerinfo1__3QYcn {
  width: 60%;
}
.PrintDocument_customerinfo-wrapper__3374Z .PrintDocument_customerinfo2__387OH {
  width: 40%;
}

.PrintDocument_customerinfo-wrapper__3374Z .PrintDocument_customerinfo1__3QYcn > div > span:nth-child(1),
.PrintDocument_customerinfo-wrapper__3374Z .PrintDocument_customerinfo1__3QYcn > div > span:nth-child(2),
.PrintDocument_customerinfo-wrapper__3374Z .PrintDocument_customerinfo2__387OH > div > span:nth-child(1),
.PrintDocument_customerinfo-wrapper__3374Z .PrintDocument_customerinfo2__387OH > div > span:nth-child(2) {
  display: inline-block;
  /* border: 1px solid black; */
  font-size: 10px;
}

.PrintDocument_customerinfo-wrapper__3374Z .PrintDocument_customerinfo1__3QYcn > div > span:nth-child(1),
.PrintDocument_customerinfo-wrapper__3374Z .PrintDocument_customerinfo2__387OH > div > span:nth-child(1) {
  width: 35%;
}
.PrintDocument_customerinfo-wrapper__3374Z .PrintDocument_customerinfo1__3QYcn > div > span:nth-child(2),
.PrintDocument_customerinfo-wrapper__3374Z .PrintDocument_customerinfo2__387OH > div > span:nth-child(2) {
  width: 65%;
}

.PrintDocument_wrapper__3O8ZP .PrintDocument_header__274r1 {
  /* .wrapper > div:nth-child(7) > div { */
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
  padding: 3px 0;
  font-weight: 400;
  display: flex;
}
.PrintDocument_wrapper__3O8ZP .PrintDocument_header__274r1 > div {
  text-align: left;
  font-size: 10px;
}

.PrintDocument_wrapper__3O8ZP .PrintDocument_header__274r1 > div:nth-child(2) {
  padding-left: 4px;
}

.PrintDocument_wrapper__3O8ZP .PrintDocument_itemsList__1VMvs {
  font-weight: 400;
  display: flex;
  font-size: 10px;
}

.PrintDocument_parent-amounttdetails__22vwV {
  position: absolute;
  bottom: 5%;
  right: 4%;
  width: 100%;
  line-height: 1;
}

.PrintDocument_wrapper__3O8ZP .PrintDocument_amounttdetails__3MysH > div:nth-child(1),
.PrintDocument_wrapper__3O8ZP .PrintDocument_amounttdetails__3MysH > div:nth-child(2),
.PrintDocument_wrapper__3O8ZP .PrintDocument_amounttdetails__3MysH > div:nth-child(3),
.PrintDocument_wrapper__3O8ZP .PrintDocument_amounttdetails__3MysH > div:nth-child(4) {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  text-align: right;
}

.PrintDocument_wrapper__3O8ZP .PrintDocument_amounttdetails__3MysH > div:nth-child(1) div:nth-child(1),
.PrintDocument_wrapper__3O8ZP .PrintDocument_amounttdetails__3MysH > div:nth-child(2) div:nth-child(1),
.PrintDocument_wrapper__3O8ZP .PrintDocument_amounttdetails__3MysH > div:nth-child(3) div:nth-child(1),
.PrintDocument_wrapper__3O8ZP .PrintDocument_amounttdetails__3MysH > div:nth-child(4) div:nth-child(1) {
  width: 80%;
  margin: 0;
  padding: 0;
}

.PrintDocument_wrapper__3O8ZP .PrintDocument_amounttdetails__3MysH > div:nth-child(1) div:nth-child(2),
.PrintDocument_wrapper__3O8ZP .PrintDocument_amounttdetails__3MysH > div:nth-child(2) div:nth-child(2),
.PrintDocument_wrapper__3O8ZP .PrintDocument_amounttdetails__3MysH > div:nth-child(3) div:nth-child(2),
.PrintDocument_wrapper__3O8ZP .PrintDocument_amounttdetails__3MysH > div:nth-child(4) div:nth-child(2) {
  width: 20%;
  padding: 0;
  margin: 0;
}

.PrintDocument_wrapper__3O8ZP .PrintDocument_footer__G16xw {
  font-weight: 800;
  position: absolute;
  bottom: 0.6%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media print {
  .PrintDocument_wrapper__3O8ZP {
    /* width: 3.75in;
    height: 4in; */
    /* a4 size 8.3x11.7 */
    width: 4.1in;
    height: 5.7in;
    border-right: 1px dashed black;
    border-left: 1px dashed black;
  }
}

.Modal_backdrop__21kXK {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 400;
  background-color: rgba(0, 0, 0, 0.75);
}

.Modal_modal__1i-gD {
  position: fixed;
  top: 4vh;
  left: 5%;
  width: 90%;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 401;
  -webkit-animation: Modal_slide-down__BNY2z 300ms ease-out forwards;
          animation: Modal_slide-down__BNY2z 300ms ease-out forwards;
}
.Modal_modal__1i-gD button {
  width: 25%;
  cursor: pointer;
  -webkit-transform: translate(100%);
          transform: translate(100%);
  margin-right: 20%;
  border-radius: 30px;
}

.Modal_modal__1i-gD .Modal_content__16dcR {
  font-weight: 600;
  font-size: 1.2em;
}

@media (min-width: 768px) {
  .Modal_modal__1i-gD {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@-webkit-keyframes Modal_slide-down__BNY2z {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes Modal_slide-down__BNY2z {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}

.PlaceOrder_wrapper__2Cb8P {
  height: 100%;
  width: 84%;
  background-color: #fff;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
}

.PlaceOrder_productsearch__2vVqm {
  display: flex;
}

/* TAB NAME,BARCCODE */
.PlaceOrder_searchoption__wxVfI {
  display: flex;
  color: #161f6a;
  color: var(--bluish-font-color);
  font-weight: 500;
  width: 25%;
}
.PlaceOrder_searchoption__wxVfI div {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ddd;
}
/* END */

.PlaceOrder_productsearch__2vVqm .PlaceOrder_inputsearch__2m8o4 {
  position: relative;
  width: 51%;
  height: 32px;
}

.PlaceOrder_productsearch__2vVqm .PlaceOrder_inputsearch__2m8o4 input[type="search"] {
  margin: 0;
  height: 100%;
  font-size: 1em;
}

.PlaceOrder_productsearch__2vVqm .PlaceOrder_deliveryDatewrapper__320js,
.PlaceOrder_productsearch__2vVqm .PlaceOrder_otherdiv__3WWg9 {
  width: 12%;
  height: 32px;
}

.PlaceOrder_productsearch__2vVqm .PlaceOrder_deliveryDate__bs4o_,
.PlaceOrder_productsearch__2vVqm .PlaceOrder_deliveryCharge__1jZmk {
  width: 100%;
  height: 32px;
  margin: 0;
  padding: 0;
  font-size: 0.9em;
}
.PlaceOrder_productsearch__2vVqm .PlaceOrder_deliveryDate__bs4o_ {
  padding-left: 12px;
}

/* .productsearch .otherdiv .deliveryCharge{
  width: 100%;
  font-size: .9rem;
} */

.PlaceOrder_product-wrapper__1a93u {
  overflow: auto;
  overflow-x: hidden;
  height: 92%;
  box-sizing: border-box;
}

/* RESULT POPUP */
.PlaceOrder_searchresult__34eRz {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 90;
  font-weight: 600;
  background-color: white;
  border: 1px solid #e8e8e8;
  border: 1px solid var(--border-color);
}

.PlaceOrder_backgroundcolor__jjxpP {
  color: #fff;
  color: var(--white-color);
  background-color: #ee4949;
  background-color: var(--theme-color);
  border: groove;
}

.PlaceOrder_header__1-k3I {
  width: 100%;
  height: 10%;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-weight: 600;
  background-color: gray;
  color: #fff;
  color: var(--white-color);
  z-index: 80;
}

.PlaceOrder_header__1-k3I div {
  border-bottom: 1px solid #e8e8e8;
  border-bottom: 1px solid var(--border-color);
  border-right: 1px solid #e8e8e8;
  border-right: 1px solid var(--border-color);
  border-collapse: collapse;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PlaceOrder_productrow__2v3Mf {
  display: flex;
  width: 100%;
  position: relative;
}
.PlaceOrder_productrow__2v3Mf div {
  border-bottom: 0.5px solid #e8e8e8;
  border-bottom: 0.5px solid var(--border-color);
  border-right: 0.5px solid #e8e8e8;
  border-right: 0.5px solid var(--border-color);
  font-weight: 600;
  color: #77798c;
  color: var(--font-color);
  display: flex;
  justify-content: center;
  cursor: pointer;
}
/* .productrow:hover {
  background-color: lightgray;
} */

.PlaceOrder_header__1-k3I div:nth-child(1),
.PlaceOrder_productrow__2v3Mf div:nth-child(1) {
  width: 5%;
}
.PlaceOrder_header__1-k3I div:nth-child(2),
.PlaceOrder_productrow__2v3Mf div:nth-child(2) {
  width: 29%;
}

.PlaceOrder_productrow__2v3Mf div:nth-child(2) {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.PlaceOrder_header__1-k3I div:nth-child(3),
.PlaceOrder_productrow__2v3Mf div:nth-child(3) {
  width: 8%;
}

.PlaceOrder_productrow__2v3Mf div:nth-child(3) input {
  border: none;
  width: 100%;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
}

.PlaceOrder_header__1-k3I div:nth-child(4),
.PlaceOrder_productrow__2v3Mf div:nth-child(4) {
  width: 8%;
}

.PlaceOrder_header__1-k3I div:nth-child(5),
.PlaceOrder_productrow__2v3Mf div:nth-child(5) {
  width: 7%;
}

.PlaceOrder_header__1-k3I div:nth-child(6),
.PlaceOrder_productrow__2v3Mf div:nth-child(6) {
  width: 10%;
}
.PlaceOrder_header__1-k3I div:nth-child(7),
.PlaceOrder_productrow__2v3Mf div:nth-child(7) {
  width: 5%;
}
.PlaceOrder_header__1-k3I div:nth-child(8),
.PlaceOrder_productrow__2v3Mf div:nth-child(8) {
  width: 10%;
}
.PlaceOrder_header__1-k3I div:nth-child(9),
.PlaceOrder_productrow__2v3Mf div:nth-child(9) {
  width: 9%;
}

.PlaceOrder_header__1-k3I div:nth-child(10),
.PlaceOrder_productrow__2v3Mf div:nth-child(10) {
  width: 10%;
}

.PlaceOrder_productrow__2v3Mf div:nth-child(10) input {
  border: none;
  width: 100%;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
}

.PlaceOrder_subtotal-wrapper__3ApKh {
  color: #77798c;
  color: var(--font-color);
  font-weight: 500;
  font-size: 1em;
  display: flex;
  flex-direction: column;
  /* background-color: yellow; */
  align-items: flex-end;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  background-color: var(--white-color);
  padding-right: 4%;
}

.PlaceOrder_subtotal-wrapper__3ApKh > div {
  width: 60%;
  /* background-color: brown; */
  text-align: right;
}

.PlaceOrder_subtotal-wrapper__3ApKh > div span:nth-child(2) {
  width: 15%;
  display: inline-block;
}

.PlaceOrder_subtotal-wrapper__3ApKh > div:nth-child(1) {
  font-weight: bold;
}

.PlaceOrder_subtotal-wrapper__3ApKh > div:nth-child(4) {
  font-size: 1.3em;
  font-weight: bold;
  color: #77798c;
  color: var(--font-color);
}

.PlaceOrder_subtotal-wrapper__3ApKh > div:nth-child(4) span:nth-child(2) {
  color: #ee4949;
  color: var(--theme-color);
}

.PlaceOrder_rowdeleteicon__a_k_N {
  position: absolute;
  right: 0;
  cursor: pointer;
  width: 20px;
  color: #f05c5c;
  color: var(--theme-color-shade1);
  height: 100%;
}

/*********** PRINTING START ***********/
@media print {
  .PlaceOrder_product-wrapper__1a93u,
  .PlaceOrder_subtotal-wrapper__3ApKh {
    width: 3.75in;
  }

  /* .product-wrapper {
    height: 2in;
  } */
  .PlaceOrder_product-wrapper__1a93u {
    padding-right: 0.009in;
  }

  .PlaceOrder_subtotal-wrapper__3ApKh {
    padding-right: 18px;
  }

  .PlaceOrder_header__1-k3I {
    background-color: #fff;
    color: #0d1136;
    color: var(--header-color);
    font-size: 0.7em;
  }
  .PlaceOrder_header__1-k3I div {
    border: none;

    justify-content: left;
  }
  .PlaceOrder_productsearch__2vVqm {
    display: none;
  }
  .PlaceOrder_productrow__2v3Mf div {
    border: none;
    display: flex;
    justify-content: flex-start;
    font-weight: 500;
    color: #0d1136;
    color: var(--header-color);
  }

  .PlaceOrder_header__1-k3I div:nth-child(1),
  .PlaceOrder_productrow__2v3Mf div:nth-child(1) {
    width: 7%;
  }
  .PlaceOrder_header__1-k3I div:nth-child(2),
  .PlaceOrder_productrow__2v3Mf div:nth-child(2) {
    width: 19%;
  }

  .PlaceOrder_header__1-k3I div:nth-child(3),
  .PlaceOrder_productrow__2v3Mf div:nth-child(3) {
    width: 7%;
  }

  .PlaceOrder_productrow__2v3Mf div:nth-child(3) input {
    text-align: left;
  }

  .PlaceOrder_header__1-k3I div:nth-child(4),
  .PlaceOrder_productrow__2v3Mf div:nth-child(4) {
    width: 12%;
  }

  .PlaceOrder_header__1-k3I div:nth-child(5),
  .PlaceOrder_productrow__2v3Mf div:nth-child(5) {
    width: 16%;
  }

  .PlaceOrder_header__1-k3I div:nth-child(6),
  .PlaceOrder_productrow__2v3Mf div:nth-child(6) {
    width: 7%;
  }

  .PlaceOrder_header__1-k3I div:nth-child(7),
  .PlaceOrder_productrow__2v3Mf div:nth-child(7) {
    width: 15%;
  }
  .PlaceOrder_header__1-k3I div:nth-child(8),
  .PlaceOrder_productrow__2v3Mf div:nth-child(8) {
    width: 15%;
  }

  .PlaceOrder_subtotal-wrapper__3ApKh > div {
    width: 100%;
    font-size: 0.9em;
    color: #161f6a;
    color: var(--bluish-font-color);
    text-align: right;
    padding: 0;
  }
  .PlaceOrder_subtotal-wrapper__3ApKh > div:nth-child(1) {
    font-weight: inherit;
  }

  .PlaceOrder_subtotal-wrapper__3ApKh > div span:nth-child(2) {
    width: 30%;
    display: inline-block;
  }

  .PlaceOrder_subtotal-wrapper__3ApKh > div:nth-child(4) {
    font-size: inherit;
    font-weight: bold;
    color: #161f6a;
    color: var(--bluish-font-color);
  }

  .PlaceOrder_subtotal-wrapper__3ApKh > div:nth-child(4) span:nth-child(2) {
    color: #161f6a;
    color: var(--bluish-font-color);
  }

  /* .productrow {
    width: 100vw;
  } */
}

/*********** PRINTING END ***********/

.ResultPopup_resultpopup-wrapper__1JwvC {
}

.ResultPopup_row__cpp9- {
  cursor: pointer;
}

.ResultPopup_row__cpp9-:hover {
  background-color: lightgray;
}

.Billing_wrapper__2T1o7 {
  width: 100%;
  height: 100%;
}

.ManageCategory_category-table__143sB {
  font-family: sans-serif;
  font-size: 1rem;
  text-align: left;
  width: 100%;
}

thead {
  background-color: #ee4949;
  color: #fff;
}

.ManageCategory_thead-Id__1dHKu {
  width: 6rem;
}

.ManageCategory_thead-name__1oCLQ {
  width: 19rem;
}

.ManageCategory_thead-nameHindi__3wM9o {
  width: 15rem;
}
.ManageCategory_thead-Image__25FWg {
  width: 10rem;
}

.ManageCategory_thead-edit__A-i_a {
  /* width: 3rem; */
  background-color: #fff;
}

.ManageCategory_thead-delete__3mbrv {
  width: 3rem;
  background-color: #fff;
}

tbody tr:nth-child(odd) {
  background-color: lightgrey;
}

tbody tr button {
  background: transparent;
  border: none;
  cursor: pointer;
  background-color: aqua;
  width: 100%;
  height: 2rem;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.ManageCategory_edit__15C1v,
.ManageCategory_delete__2CfpJ {
  width: 2rem;
  background-color: #fff;
}

.AddCategory_form__OqxWx {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding: 1rem 0 1rem 0;
  background-color: lightgrey;
}

.AddCategory_innerform__28cGK {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.AddCategory_label-input__23RPU {
  display: flex;
  flex-direction: column;
}

.AddCategory_label-input__23RPU label {
  text-align: left;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
}

.AddCategory_label-input__23RPU input {
  height: 2.4rem;
  width: 14rem;
  outline-color: #ee4949;
  border: 0.1px solid gray;
}

.AddCategory_buttondiv__1KLqk button {
  width: 8rem;
  height: 3rem;
  background-color: #ee4949;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 400;
}

.AddCategory_buttondiv__1KLqk button:hover {
  background-color: #ec6e6e;
}

.ManageSubCategory_category-table__3C_Si {
  font-family: sans-serif;
  font-size: 1rem;
  text-align: left;
  width: 100%;
}

thead {
  background-color: #ee4949;
  color: #fff;
}

.ManageSubCategory_thead-Id__1D9_J {
  width: 6rem;
}

.ManageSubCategory_thead-name__2PWCr {
  width: 19rem;
}

.ManageSubCategory_thead-nameHindi__1E_YI {
  width: 15rem;
}

.ManageSubCategory_thead-Image__UwPKN {
  width: 10rem;
}

tbody tr {
  height: 1rem;
}

tbody tr:nth-child(odd) {
  background-color: lightgrey;
}

tbody tr button {
  background: transparent;
  border: none;
  cursor: pointer;
  background-color: aqua;
  width: 100%;

  margin: 0;
  padding: 0;
  display: inline-block;
}

.ManageSubCategory_edit__39ezz,
.ManageSubCategory_delete__1PTpM {
  width: 2rem;
  background-color: #fff;
}

.ManageSubCategory_thead-edit__qGAlW {
  /* width: 3rem; */
  background-color: #fff;
}

.ManageSubCategory_thead-delete__2sIIp {
  width: 3rem;
  background-color: #fff;
}

.AddSubCategory_form__1QthY {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding: 1rem 0 1rem 0;
  background-color: lightgrey;
}

.AddSubCategory_innerform__1NKwc {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.AddSubCategory_label-input__2X6_J {
  display: flex;
  flex-direction: column;
}

.AddSubCategory_label-input__2X6_J label {
  text-align: left;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
}

.AddSubCategory_label-input__2X6_J input {
  height: 2.4rem;
  width: 14rem;
  outline-color: #ee4949;
  border: 0.1px solid gray;
}

.AddSubCategory_buttondiv__1yXYl button {
  width: 8rem;
  height: 3rem;
  background-color: #ee4949;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 400;
}

.AddSubCategory_buttondiv__1yXYl button:hover {
  background-color: #ec6e6e;
}

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --font-color1: #777777;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
  --border-color1: #ddd;
  --textbox-bg-color: #f9f9f9;
  --header-bg-color1: #333333;
  --header-bg-color2: #002933;
  --header-bg-color3: #36304a;
}

.ConfirmationModal_backdrop__1QAFN {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 400;
  background-color: rgba(0, 0, 0, 0.75);
}

.ConfirmationModal_confirmmodal__3sXkR {
  position: fixed;
  top: 7vh;
  left: 0;
  width: 100%;
  background-color: transparent;
  z-index: 401;
  -webkit-animation: ConfirmationModal_slide-down__2cbjX 300ms ease-out forwards;
          animation: ConfirmationModal_slide-down__2cbjX 300ms ease-out forwards;
  font-family: "Poppins", sans-serif;
}

.ConfirmationModal_confirmmodal__3sXkR .ConfirmationModal_content__1zk57 {
  border-radius: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 28%;
  padding: 1rem;
  line-height: 2.8;
  position: relative;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  font-weight: 500;
  font-size: 1.2em;
}

.ConfirmationModal_confirmmodal__3sXkR .ConfirmationModal_content__1zk57 .ConfirmationModal_button-wrapper__23mQe {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 3%;
}

.ConfirmationModal_confirmmodal__3sXkR .ConfirmationModal_content__1zk57 .ConfirmationModal_button-wrapper__23mQe button {
  width: 10rem;
  height: 40px;
  margin: 0;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f05c5c;
  background-color: var(--theme-color-shade1);
}

.ConfirmationModal_confirmmodal__3sXkR .ConfirmationModal_closebutton__2vC9o {
  position: absolute;
  top: 0;
  right: 0;
  color: #77798c;
  color: var(--font-color);
  cursor: pointer;
  width: 20%;
  height: 20%;
}

.ConfirmationModal_confirmmodal__3sXkR .ConfirmationModal_button-wrapper__23mQe button:nth-child(1):hover {
  background-color: transparent;
  color: #ee4949;
  color: var(--theme-color);
  border: 1px solid #ee4949;
  border: 1px solid var(--theme-color);
}

@media (min-width: 768px) {
  /* .confirmmodal {
    width: 40rem;
    left: calc(50% - 20rem);
  } */
}

@-webkit-keyframes ConfirmationModal_slide-down__2cbjX {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes ConfirmationModal_slide-down__2cbjX {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.Category_container__1gg8g {
  background-color: #fff;
  margin-left: 2rem;
  margin-top: 1rem;
  margin-right: 2rem;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

.Navigation_navigation-wrapper__txa6o {
  display: flex;
  font-family: sans-serif;
  background-color: #fff;
  box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.09);
  /* position: relative; */
  height: 100%;
  padding: 0;
}

.Navigation_logo-div__2AZ1e {
  height: 100%;
  width: 15%;
  align-items: center;
  display: flex;
  padding-left: 1%;
}

.Navigation_logo-div__2AZ1e img {
  width: 150px;
  height: 40px;
}

.Navigation_userdetails-div__2plFv {
  width: 75%;
  height: 100%;
  text-align: right;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.Navigation_userdetails-div__2plFv strong {
  font-size: 20px;
}

.Navigation_time-cell__3Q651 {
  font-weight: 500;
}

.Navigation_user-pic-setting-wrapper__1gI9J {
  width: 10%;
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Navigation_profile-box__LfHQu {
  width: 2.8rem;
  height: 2.8rem;
  cursor: pointer;
  color: gray;
  position: relative;
}

.Navigation_profile-setting__2H94C {
  width: 10rem;
  height: 8rem;
  display: flex;
  flex-direction: column;
  grid-gap: 14px;
  gap: 14px;
  position: absolute;
  right: 3.8%;
  top: 7.5%;
  margin: 0;
  padding: 20px 0 0 10px;
  box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.Navigation_profile-setting__2H94C li {
  list-style: none;
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  margin-left: 10px;
}

.SideMenu_aside-container__16jOs {
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 92vh;
  background-color: #0d1136;
  opacity: 0.8;
  font-family: "Lato", sans-serif;
}

.SideMenu_dashBoard__ZpT6I,
.SideMenu_category__2L4dX,
.SideMenu_product__1qCK5,
.SideMenu_purchase__3RtWF,
.SideMenu_pricing__1o6Z_,
.SideMenu_customer__3pqHT,
.SideMenu_order__2KNXt,
.SideMenu_store__2ABSp,
.SideMenu_reports__B2YZD,
.SideMenu_billing__2_KMz {
  /* color: #fff; */
  color: #ffffffcc;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  border-bottom: 1px solid #ffffff1a;
  margin-bottom: 1px;
  margin-left: 2rem;
  margin-right: 2rem;
}

.SideMenu_dashBoard__ZpT6I {
  margin-top: 20px;
}

.SideMenu_dashBoard__ZpT6I p,
.SideMenu_category__2L4dX p,
.SideMenu_product__1qCK5 p,
.SideMenu_purchase__3RtWF p,
.SideMenu_pricing__1o6Z_ p,
.SideMenu_customer__3pqHT p,
.SideMenu_order__2KNXt p,
.SideMenu_store__2ABSp p,
.SideMenu_reports__B2YZD p,
.SideMenu_billing__2_KMz p {
  text-align: left;
}

ul {
  /* padding: 0.6rem 0rem 0.6rem 3.1rem; */
  margin: 0px;
  /* display: flex;
  flex-direction: column; */
  /* margin: 0 0 0 1.4rem; */
}

a + ul li {
  font-size: 16px;
  font-weight: 400;
  padding-top: 0.6rem;
  padding-bottom: 1rem;
  margin-right: 2rem;
  margin-left: 3rem;
  list-style: none;
  cursor: pointer;
  border-bottom: 1px solid #ffffff1a;
  color: #ffffffcc;
}

:root {
  --theme-color: #ee4949;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
}

.LabelInput_labelinput-wrapper__2Buqj {
  display: flex;
  flex-direction: column;
  font-family: "Lato", sans-serif;
  text-align: left;
}

.LabelInput_names__1nB7J {
  font-weight: 600;
  font-size: 17px;
  color: #161f6a;
  color: var(--bluish-font-color);
  text-align: left;
}
.LabelInput_names__1nB7J:hover {
  background-color: transparent;
}

.LabelInput_inputs__Wt-9p {
  height: 46px;
  margin-top: 1%;
  margin-bottom: 4%;
  border-radius: 4px;
  border-style: none;
  background-color: #f9f9f9;
  border: 0.4px solid #ddd;
  font-weight: 400;
  font-size: 17px;
  color: #77798c;
  color: var(--font-color);
  padding-left: 1%;
}

.LabelInput_inputs__Wt-9p:focus {
  border-width: 0.5px;
  outline-color: #ee4949;
}

.MainSectionHeader_header__3ziBb {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  min-height: 10%;
  /* border: 1px solid black; */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  color: var(--bluish-font-color);
  font-weight: 600;
  font-size: 1.8em;
  padding-left: 2%;
}

.MainSectionHeader_header__3ziBb > span {
  font-weight: 500;
  font-family: "Lato", sans-serif;
  color: #777;
  margin-left: 4%;
  font-size: 0.7em;
}

.MainSectionHeader_header__3ziBb p {
  cursor: pointer;
  display: inline;
}

:root {
  --theme-color: #ee4949;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --white-color: #fff;
}

.Registration_main-wrapper__2oQXT {
  box-sizing: border-box;
  background-color: #fff;
  background-color: var(--white-color);
  width: 92%;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 1%;
  margin-left: 4%;
  margin-right: 4%;
  color: #77798c;
  text-align: left;
  box-shadow: 0.6px 0.6px 2px #ddd;
}

.Registration_main-wrapper__2oQXT div {
  text-align: right;
}

.Registration_buttons__2ahnj {
  margin-top: 3%;
  width: 30%;
  font-size: 1.5em;
}

.Registration_termscondition__2Yahj input {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-right: 0.5%;
}
.Registration_termscondition__2Yahj span {
  font-weight: 300;
}
.Registration_termscondition__2Yahj u {
  color: #ee4949;
  color: var(--theme-color);
  font-weight: 400;
  cursor: pointer;
}

.Registration_city-wrapper__3qlaT {
  position: relative;
  /* border: 1px solid black; */
}
.Registration_citylist__3AvSK {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 40;
  top: 72px;
  left: 3px;
  background-color: #f9f9f9;
  width: 50%;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 100px;
  overflow: auto;
  border: 1px solid #ddd;
  border-top: none;
  box-shadow: 1px 1px 0 #ddd;
}
.Registration_citylist__3AvSK span {
  text-align: left;
  padding-left: 8px;
  cursor: pointer;
  padding-top: 8px;
  font-weight: 400;
}

.Registration_citylist__3AvSK span:hover {
  background-color: lightgray;
}

:root {
  --theme-color: #ee4949;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
}

.SearchableDropdown_city-wrapper__12j1- {
  position: relative;
  /* border: 1px solid black; */

  display: flex;
  flex-direction: column;
}
.SearchableDropdown_citylist__1FdWV {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 40;
  top: 72px;
  left: 3px;
  background-color: #f9f9f9;
  width: 50%;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 100px;
  overflow: auto;
  border: 1px solid #ddd;
  border-top: none;
  box-shadow: 1px 1px 0 #ddd;
}
.SearchableDropdown_names__1wPta {
  font-weight: 600;
  font-size: 17px;
  color: #161f6a;
  color: var(--bluish-font-color);
  text-align: left;
}
.SearchableDropdown_span__3wayn {
  text-align: left;
  padding-left: 8px;
  cursor: pointer;
  padding-top: 8px;
  font-weight: 400;
}

.SearchableDropdown_span__3wayn:hover {
  background-color: lightgray;
}

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --font-color1: #777777;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
  --border-color1: #ddd;
  --textbox-bg-color: #f9f9f9;
  --header-bg-color1: #333333;
  --header-bg-color2: #002933;
  --header-bg-color3: #36304a;
}

.OrderInformation_orderinfo__3bLyt {
  position: absolute;
  bottom: 0;
  background-color: oldlace;
  width: 100%;
  font-family: "Poppins" sans-serif;
  opacity: 0.8;
  display: flex;
}

/* .orderinfo > div {
  display: flex;
} */

.OrderInformation_orderinfo__3bLyt > div:nth-child(1),
.OrderInformation_orderinfo__3bLyt > div:nth-child(2),
.OrderInformation_orderinfo__3bLyt > div:nth-child(3),
.OrderInformation_orderinfo__3bLyt > div:nth-child(4) {
  width: 33%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.OrderInformation_orderinfo__3bLyt > div:nth-child(1) > div:nth-child(1),
.OrderInformation_orderinfo__3bLyt > div:nth-child(1) > div:nth-child(3),
.OrderInformation_orderinfo__3bLyt > div:nth-child(1) > div:nth-child(5),
.OrderInformation_orderinfo__3bLyt > div:nth-child(2) > div:nth-child(1),
.OrderInformation_orderinfo__3bLyt > div:nth-child(2) > div:nth-child(3),
.OrderInformation_orderinfo__3bLyt > div:nth-child(2) > div:nth-child(5),
.OrderInformation_orderinfo__3bLyt > div:nth-child(3) > div:nth-child(1),
.OrderInformation_orderinfo__3bLyt > div:nth-child(3) > div:nth-child(3),
.OrderInformation_orderinfo__3bLyt > div:nth-child(3) > div:nth-child(5),
.OrderInformation_orderinfo__3bLyt > div:nth-child(4) > div:nth-child(1),
.OrderInformation_orderinfo__3bLyt > div:nth-child(4) > div:nth-child(3),
.OrderInformation_orderinfo__3bLyt > div:nth-child(4) > div:nth-child(5) {
  color: maroon;
  font-weight: 800;
  width: 100%;
  width: 80%;
  text-align: right;
}

.OrderInformation_orderinfo__3bLyt > div:nth-child(1) > div:nth-child(2),
.OrderInformation_orderinfo__3bLyt > div:nth-child(1) > div:nth-child(4),
.OrderInformation_orderinfo__3bLyt > div:nth-child(1) > div:nth-child(6),
.OrderInformation_orderinfo__3bLyt > div:nth-child(2) > div:nth-child(2),
.OrderInformation_orderinfo__3bLyt > div:nth-child(2) > div:nth-child(4),
.OrderInformation_orderinfo__3bLyt > div:nth-child(2) > div:nth-child(6),
.OrderInformation_orderinfo__3bLyt > div:nth-child(3) > div:nth-child(2),
.OrderInformation_orderinfo__3bLyt > div:nth-child(3) > div:nth-child(4),
.OrderInformation_orderinfo__3bLyt > div:nth-child(3) > div:nth-child(6),
.OrderInformation_orderinfo__3bLyt > div:nth-child(4) > div:nth-child(2),
.OrderInformation_orderinfo__3bLyt > div:nth-child(4) > div:nth-child(4),
.OrderInformation_orderinfo__3bLyt > div:nth-child(4) > div:nth-child(6) {
  font-weight: 600;
  color: black;
  width: 20%;
  padding-left: 10px;
}

.OrdersDetailFilter_orderdetail-filter-wrapper__3ZZuJ {
  height: 15%;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  display: flex;
  flex-direction: column;
}

.OrdersDetailFilter_date-wrapper__23pqw {
  height: 70%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.OrdersDetailFilter_date-wrapper__23pqw .OrdersDetailFilter_range__36-ry {
  font-size: 14px;
  font-weight: 500;
}

.OrdersDetailFilter_from__26Mu0 {
  font-size: 14px;
  font-weight: 300;
}
.OrdersDetailFilter_orderdetail-filter-wrapper__3ZZuJ .OrdersDetailFilter_searchdiv__2nSUv {
  width: 10%;
}
.OrdersDetailFilter_orderdetail-filter-wrapper__3ZZuJ .OrdersDetailFilter_searchdiv__2nSUv .OrdersDetailFilter_searchButton__11N5u {
  width: 25%;
  height: 25%;
  cursor: pointer;
}

.OrdersDetailFilter_orderdetail-filter-wrapper__3ZZuJ .OrdersDetailFilter_tabs__2Iibj {
  color: black;
  font-weight: 500;
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: row;
}

.OrdersDetailFilter_tabs__2Iibj div {
  height: 100%;
  width: 15%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #9b59b6;
}

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --font-color1: #777777;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
  --border-color1: #ddd;
  --textbox-bg-color: #f9f9f9;
  --header-bg-color1: #333333;
  --header-bg-color2: #002933;
  --header-bg-color3: #36304a;
}

.OrdersDetailList_order-table-wrapper__XA_i5 {
  height: 85%;
  padding: 8px;
}


.OrdersDetailList_order-table-wrapper__XA_i5 .OrdersDetailList_tab__2wu9a > div {
  border: 1px solid #9b59b6;
  display: flex;
  width: 14%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 1em;
}

.OrdersDetailList_tablehead__2fS7V,
.OrdersDetailList_tablerow__3_QS7 {
  display: flex;
  flex-direction: row;
  font-size: 12px;
}
.OrdersDetailList_tablehead__2fS7V {
  height: 6%;
}
.OrdersDetailList_tablerow__3_QS7 {
  height: 12%;
}

.OrdersDetailList_tablehead__2fS7V {
  font-weight: 600;
  word-wrap: break-word;
  background-color: #333333;
  background-color: var(--header-bg-color1);
  color: white;
  width: 99.3%;
  table-layout: fixed;
}

.OrdersDetailList_rowswrapper__owJHY {
  overflow-y: scroll;
  height: 80%;
}

.OrdersDetailList_tablerow__3_QS7 {
  font-weight: 500;
}

.OrdersDetailList_tablehead__2fS7V > span,
.OrdersDetailList_tablerow__3_QS7 > span {
  align-self: center;
}

.OrdersDetailList_tablerow__3_QS7 > span:nth-last-child(3),
.OrdersDetailList_tablerow__3_QS7 > span:nth-child(3) {
  font-weight: 700;
}

.OrdersDetailList_tablerow__3_QS7:nth-child(odd) {
  background-color: #0000000d;
}

.OrdersDetailList_editspan__1FREe,
.OrdersDetailList_deletespan__258C5 {
  padding-left: 15px;
  cursor: pointer;
}

.OrdersDetailList_click__28ZN6 {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}

.OrdersDetailList_deleteicon__xw5Pa {
  width: 40%;
  height: 40%;
}

.OrdersDetailList_editicon__3kIDd {
  width: 50%;
  height: 50%;
}

.OrdersDetailList_viewBill__1-qT4 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
}

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --font-color1: #777777;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
  --border-color1: #ddd;
  --textbox-bg-color: #f9f9f9;
  --header-bg-color1: #333333;
  --header-bg-color2: #002933;
  --header-bg-color3: #36304a;
}

.OrderDetailEdit_editwrapper__1Gf5- {
  z-index: 50;
}

/* .livestock-edit-wrapper {
  display: flex;
  flex-direction: column;
  line-height: 2;
  margin: -2px;
  position: relative;
} */

/* .livestock-edit-wrapper > div {
  height: 3vh;
} */

/* .livestock-edit-wrapper > div > span:nth-child(1) {
  width: 35%;
  display: inline-block;
  font-size: 14px;
}

.livestock-edit-wrapper > div > span:nth-child(2),
.livestock-edit-wrapper > div .textbox {
  width: 65%;
  display: inline-block;
  font-size: 12px;
} */

/* .livestock-edit-wrapper > div .textbox {
  border: 1px solid var(--border-color1);
} */

/* .closebutton {
  position: absolute;
  top: 0px;
  right: -8px;
  color: var(--font-color);
  cursor: pointer;
  width: 15%;
  height: 15%;
} */

/* .button-wrapper {
  display: flex;
  flex-direction: row;
  background-color: yellow;
  align-items: flex-end;
}

.livestock-edit-wrapper .button-wrapper button {
  width: fit-content;
  border-radius: 0;
  min-height: 50%;
} */

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --font-color1: #777777;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
  --border-color1: #ddd;
  --textbox-bg-color: #f9f9f9;
  --header-bg-color1: #333333;
  --header-bg-color2: #002933;
  --header-bg-color3: #36304a;
}

.EditPopup_backdrop__1GW1W {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 400;
  background-color: rgba(0, 0, 0, 0.75);
}

.EditPopup_editmodal__1Bi1F {
  position: fixed;
  top: 7vh;
  left: 0;
  width: 100%;
  background-color: transparent;
  z-index: 401;
  -webkit-animation: EditPopup_slide-down__1zyKv 300ms ease-out forwards;
          animation: EditPopup_slide-down__1zyKv 300ms ease-out forwards;
  font-family: "Poppins", sans-serif;
  text-align: left;
}

.EditPopup_editmodal__1Bi1F .EditPopup_content__3R2T3 {
  border-radius: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 35%;
  padding: 1rem;
  line-height: 2.8;
  position: relative;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.EditPopup_editmodal__1Bi1F .EditPopup_content__3R2T3 span:nth-child(1),
.EditPopup_editmodal__1Bi1F .EditPopup_content__3R2T3 span:nth-child(2) {
  display: inline-block;
}

.EditPopup_editmodal__1Bi1F .EditPopup_content__3R2T3 span:nth-child(1) {
  width: 40%;
  font-weight: 600;
}
.EditPopup_editmodal__1Bi1F .EditPopup_content__3R2T3 span:nth-child(2) {
  width: 60%;
  font-weight: 400;
}

.EditPopup_editmodal__1Bi1F .EditPopup_content__3R2T3 input {
  width: 30%;
  border: 1px solid #e8e8e8;
  border: 1px solid var(--border-color);
  outline-color: #f48a8a;
  outline-color: var(--theme-color-shade2);
  height: 30px;
  /* border: 1px solid var(--font-color); */
}

.EditPopup_editmodal__1Bi1F .EditPopup_closebutton__MwCI7 {
  position: absolute;
  top: 0;
  right: 0;
  color: #77798c;
  color: var(--font-color);
  cursor: pointer;
  width: 15%;
  height: 15%;
}

.EditPopup_editmodal__1Bi1F .EditPopup_content__3R2T3 .EditPopup_button-wrapper__2Eiv8 {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 3%;
}

.EditPopup_editmodal__1Bi1F .EditPopup_content__3R2T3 .EditPopup_button-wrapper__2Eiv8 button {
  width: 10rem;
  height: 40px;
  margin: 0;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f05c5c;
  background-color: var(--theme-color-shade1);
}

.EditPopup_editmodal__1Bi1F .EditPopup_button-wrapper__2Eiv8 button:nth-child(1) {
  margin-right: 30px;
}
.EditPopup_editmodal__1Bi1F .EditPopup_button-wrapper__2Eiv8 button:nth-child(2) {
  background-color: transparent;
  color: #ee4949;
  color: var(--theme-color);
  border: 1px solid #ee4949;
  border: 1px solid var(--theme-color);
}

.EditPopup_editmodal__1Bi1F .EditPopup_button-wrapper__2Eiv8 button:nth-child(1):hover {
  background-color: transparent;
  color: #ee4949;
  color: var(--theme-color);
  border: 1px solid #ee4949;
  border: 1px solid var(--theme-color);
}

.EditPopup_editmodal__1Bi1F .EditPopup_button-wrapper__2Eiv8 button:nth-child(2):hover {
  border: 2px solid #ee4949;
  border: 2px solid var(--theme-color);
}

@media (min-width: 768px) {
  .EditPopup_modal__2sj8W {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@-webkit-keyframes EditPopup_slide-down__1zyKv {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes EditPopup_slide-down__1zyKv {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.OrdersDetail_order-detail-wrapper__3ByJP {
  height: 100%;
  width: 100%;
  margin-top: 0;
  padding: 0;
  position: relative;
}

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --header-color2: #d8e3f4;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}

.SetItemPrice_main-div-stocklist__2LaxR {
  font-family: "Lato", sans-serif;
  height: 88%;
  overflow: auto;
  width: 100%;
}

.SetItemPrice_tableHeader__1R03p,
.SetItemPrice_tableData__ClTnB {
  display: flex;
  font-size: 0.9em;
  width: 100%;
  text-align: center;
}

.SetItemPrice_tableHeader__1R03p {
  font-weight: 800;
  color: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.SetItemPrice_tableData__ClTnB {
  font-weight: 500;
  color: #77798c;
  color: var(--font-color);
}

.SetItemPrice_tableHeader__1R03p div {
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  background-color: #bee6ec;
  /* #ddf0f6; */
  color: black;
  padding: 6px 0 6px 0;
}

.SetItemPrice_tableData__ClTnB div {
  color: black;
  padding: 8px 0 8px 0;
  /* border-left: 1px solid white; */
  border-bottom: 1px solid gray;
  background-color: #ddf0f6;
}
.SetItemPrice_tableHeader__1R03p div > div,
.SetItemPrice_tableData__ClTnB div > div {
  border-bottom: none;
}
.SetItemPrice_main-div-stocklist__2LaxR > div:nth-child(even) {
  background-color: #e4e4e4;
}

.SetItemPrice_tableData__ClTnB div:nth-child(7),
.SetItemPrice_tableData__ClTnB div:nth-child(8),
.SetItemPrice_tableData__ClTnB div:nth-child(9) {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0;
  padding: 0;
}
.SetItemPrice_tableData__ClTnB div:nth-child(7) div:nth-child(1) {
  width: 30%;
  border-left: none;
}

.SetItemPrice_tableData__ClTnB div:nth-child(7) div:nth-child(2),
.SetItemPrice_tableData__ClTnB div:nth-child(7) div:nth-child(4) {
  width: 22%;
}
.SetItemPrice_tableData__ClTnB div:nth-child(7) div:nth-child(4) {
  border-right: none;
}
.SetItemPrice_tableData__ClTnB div:nth-child(7) div:nth-child(3) {
  width: 26%;
}

.SetItemPrice_tableData__ClTnB div:nth-child(8) div:nth-child(1),
.SetItemPrice_tableData__ClTnB div:nth-child(8) div:nth-child(3),
.SetItemPrice_tableData__ClTnB div:nth-child(9) div:nth-child(1),
.SetItemPrice_tableData__ClTnB div:nth-child(9) div:nth-child(3) {
  width: 30%;
}
.SetItemPrice_tableData__ClTnB div:nth-child(8) div:nth-child(2),
.SetItemPrice_tableData__ClTnB div:nth-child(9) div:nth-child(2) {
  width: 40%;
}

.SetItemPrice_tableData__ClTnB div:nth-child(6) {
  cursor: pointer;
}

.SetItemPrice_tableData__ClTnB div:nth-child(13) {
  cursor: pointer;
}

.SetItemPrice_tableData__ClTnB div:nth-child(14) {
  cursor: pointer;
}

.SetItemPrice_modalbutton__1k6tt {
  cursor: pointer;
}

.SetItemPrice_accrodiandiv__2BAJe {
  font-size: 1.1em;
  font-weight: 600;
  width: 60%;
  margin: auto;
}

.SetItemPrice_accrodiandiv__2BAJe .SetItemPrice_header__21e4A {
  display: flex;
}

.SetItemPrice_accrodiandiv__2BAJe .SetItemPrice_header__21e4A div {
  background-color: #161f6a;
  color: white;
  font-size: 0.8em;
}

.SetItemPrice_accrodiandiv__2BAJe .SetItemPrice_data__1AK4K {
  display: flex;
}

.SetItemPrice_checkbox__2ijlr {
  cursor: pointer;
}
.SetItemPrice_update__1lpOV:hover {
  border: 1px solid lightgray;
  background-color: yellow;
}

.SetItemPrice_tab__28L9r {
  display: flex;
  flex-direction: row;
}
.SetItemPrice_normalbuttonstyle__1QlY_ {
  border-radius: 0;
  margin: 0;
  cursor: pointer;
  width: 10%;
  background-color: transparent;
  color: #77798c;
  color: var(--font-color);
  font-size: 14px;
}

.SetItemPrice_buttonclickstyle__3-saB {
  border: 1px solid black;
  background-color: #d8e3f4;
  background-color: var(--header-color2);
  color: black;
  font-weight: 600;
  border-top: none;
}

.Add-Filter_maindiv__1NsrK {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0 1rem 0;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  background-color: lightgray;
}

.Add-Filter_innerdiv__3hQJG {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.Add-Filter_paddingDropdown__2IPhn {
  padding: 5px;
  display: inline-block;
}

.Add-Filter_height__3l3xL {
  height: 5rem;
}

.Add-Filter_maindiv__1NsrK svg {
  height: 2rem;
}

.Add-Filter_buttondiv__21H_X {
  align-items: flex-end;
}

.Add-Filter_buttondiv__21H_X button {
  width: 12rem;
  height: 3rem;
  background-color: #ee4949;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 400;
}

.Add-Filter_submitDiv__2madt {
  border: none;
  background-color: transparent;
  height: 0;
}

.Add-Filter_buttondiv__21H_X button:hover {
  background-color: #ec6e6e;
}

.Button_buttonControl1__PHvvZ {
  width: 12rem;
  height: 3rem;
  background-color: #ee4949;
  border: none;
  color: white;
  font-weight: 400;
  font-size: 1.3rem;
  cursor: pointer;
  margin: 0;
}

.Product_container__26nNL {
  background-color: #fff;
  margin-left: 2rem;
  margin-top: 1rem;
  margin-right: 2rem;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}
.ListPurchase_main-div-stocklist__2fnxw {
  font-family: "Lato", sans-serif;
  height: 88%;
  overflow: auto;
  width: 100%;
}
.ListPurchase_main-div-stocklist__2fnxw thead tr th,
.ListPurchase_main-div-stocklist__2fnxw tbody tr td {
  font-size: 0.9em;
  width: 100%;
  text-align: center;
}

.ListPurchase_main-div-stocklist__2fnxw thead tr th {
  font-weight: 800;
  color: white;
}

.ListPurchase_main-div-stocklist__2fnxw tbody tr td {
  font-weight: 500;
  color: #77798c;
  color: var(--font-color);
}
.ListPurchase_main-div-stocklist__2fnxw .ListPurchase_edit__3SVxN,
.ListPurchase_main-div-stocklist__2fnxw .ListPurchase_delete__1rnsw,
.ListPurchase_main-div-stocklist__2fnxw .ListPurchase_status__10Ktk {
  width: 2.3em;

  font-size: small;
  border-radius: 4px;
  border-style: none;
  background-color: lightseagreen;
}

.ListPurchase_main-div-stocklist__2fnxw .ListPurchase_delete__1rnsw {
  width: 3em;
}
.ListPurchase_main-div-stocklist__2fnxw .ListPurchase_status__10Ktk {
  width: 6em;
}
.ListPurchase_pending__2xpu7 {
  color: red;
  font-weight: bold;
}

.ListPurchase_none__1pvpc {
  font-weight: bold;
}

.ListPurchase_complete__3icXd {
  color: blueviolet;
  font-weight: bolder;
}

.ListPurchase_purchaseinfo__1hTQQ {
  display: flex;
  flex-direction: row;
}

.ListPurchase_purchaseinfo__1hTQQ > div {
  width: 40%;
  font-weight: 600;
}

.ListPurchase_purchaseinfo__1hTQQ > div span {
  display: inline-block;
}

.ListPurchase_purchaseinfo__1hTQQ > div > div span:nth-child(1) {
  color: red;
  text-align: right;
  width: 30%;
}
.ListPurchase_purchaseinfo__1hTQQ > div > div span:nth-child(2) {
  color: black;
  padding-left: 10px;
  width: 20%;
}

.AddForm_addform-container__3IG-w {
  width: 60vw;
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1%;
  background-color: #eeeeee;
  border-radius: 5px;
}

.AddForm_super__lymsF {
  color: red;
}

.AddForm_leftwrapper__2HV_I,
.AddForm_rightwrapper__2WzSv {
  width: 50%;
}

.AddForm_itemdetails__23IhG,
.AddForm_purchasestatus__3mC5C,
.AddForm_bottomwrapper__3bMVh {
  width: 100%;
}

.AddForm_itemdetails__23IhG > div,
.AddForm_purchasestatus__3mC5C > div,
.AddForm_taxdetails__2miHq > div,
.AddForm_vendorpayment__1J0E- > div,
.AddForm_otherdetails__Qwpno > div {
  display: flex;
  flex-direction: row;

  width: 100%;
}

.AddForm_itemdetails__23IhG > div div:nth-child(odd),
.AddForm_purchasestatus__3mC5C > div div:nth-child(odd),
.AddForm_taxdetails__2miHq > div div:nth-child(odd),
.AddForm_vendorpayment__1J0E- > div div:nth-child(odd),
.AddForm_otherdetails__Qwpno > div div:nth-child(odd) {
  width: 45%;
  text-align: left;
  padding: 0;
  font-weight: 400;
  word-wrap: break-word;
}

.AddForm_itemdetails__23IhG > div div:nth-child(even),
.AddForm_purchasestatus__3mC5C > div div:nth-child(even),
.AddForm_taxdetails__2miHq > div div:nth-child(even),
.AddForm_vendorpayment__1J0E- > div div:nth-child(even),
.AddForm_otherdetails__Qwpno > div div:nth-child(even) {
  width: 55%;
  text-align: left;
  padding: 0.8% 0;
}
.AddForm_itemdetails__23IhG > div div:nth-child(even) input,
.AddForm_purchasestatus__3mC5C > div div:nth-child(even) input,
.AddForm_taxdetails__2miHq > div div:nth-child(even) input,
.AddForm_vendorpayment__1J0E- > div div:nth-child(even) input,
.AddForm_otherdetails__Qwpno > div div:nth-child(even) input {
  width: 80%;
  border-style: none;
  border: 1px solid black;
}

.AddForm_dropdown__3rdc9 {
  width: 80%;
  border-left: 1px solid black;
}

.AddForm_itemdetails__23IhG > div:nth-child(1),
.AddForm_purchasestatus__3mC5C > div:nth-child(1),
.AddForm_taxdetails__2miHq > div:nth-child(1),
.AddForm_vendorpayment__1J0E- > div:nth-child(1),
.AddForm_otherdetails__Qwpno > div:nth-child(1) {
  justify-content: center;
  font-weight: 400;
  color: white;
  background-color: #ee4949;
}

.AddForm_bottomwrapper__3bMVh > div:nth-child(1) {
  margin-top: 10px;
}

.AddForm_bottomwrapper__3bMVh > div:nth-child(2) input {
  width: 8%;
  text-align: center;
}

.AddForm_bottomwrapper__3bMVh .AddForm_buttonswrap__2kT3s {
  display: flex;
  flex-direction: row;
}

.AddForm_bottomwrapper__3bMVh .AddForm_buttonswrap__2kT3s div:nth-child(1) {
  width: 70%;
  padding-left: 46%;
}

.AddForm_bottomwrapper__3bMVh .AddForm_buttonswrap__2kT3s div:nth-child(2) {
  width: 30%;
  padding: 0 1% 0 10%;
}

.AddForm_bottomwrapper__3bMVh .AddForm_buttonswrap__2kT3s div button {
  margin: 0;
  cursor: pointer;
}
.AddForm_bottomwrapper__3bMVh .AddForm_buttonswrap__2kT3s div button:hover {
  border: 1px solid #e4e4e4;
}

.AddForm_bottomwrapper__3bMVh .AddForm_finalmetric__O9FtH {
  margin: 10px 0 10px 0;
}

.AddForm_plusminusicons__zBZ_q {
  cursor: pointer;
  width: 22px;
  height: 22px;
}

.ModalPopup_background__T2TGn {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100vh;
  z-index: 25;
  left: 0;
  top: 0;
}

.ModalPopup_modal__1tt2d {
  z-index: 30;
  position: fixed;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}

.AddFilter_wrapper__3zCFi {
  display: flex;
  height: 12%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 0 2% 0;
  margin: 0;
}

.AddFilter_wrapper__3zCFi div {
  /* display: inline-block; */
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  align-items: flex-start;
}

.AddFilter_wrapper__3zCFi button {
  position: relative;
  margin: 0;
  width: auto;
  background-color: transparent;
}
.AddFilter_wrapper__3zCFi .AddFilter_button__2ch7j {
  position: absolute;
  bottom: 3px;
  cursor: pointer;
  margin-top: 10px;
  width: 30px;
  height: 30px;
  fill: black;
}

.AddFilter_catg__2jjJP,
.AddFilter_subcatg__35V-m,
.AddFilter_product__1zuzj,
.AddFilter_choice__17ACx {
  height: 22px;
}

.AddFilter_catg__2jjJP {
  width: 16em;
}
.AddFilter_subcatg__35V-m {
  width: 15em;
}
.AddFilter_product__1zuzj {
  width: 15em;
}
.AddFilter_choice__17ACx {
  width: 10em;
}

.Purchase_container__2BZvD {
  background-color: #fff;
  margin-left: 2rem;
  margin-top: 1rem;
  margin-right: 2rem;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

.Error_error__1uIRr {
  position: absolute;
  right: 3%;
  top: 16%;
}

.Error_error__1uIRr svg {
  height: 1rem;
  width: 1rem;
  padding-bottom: 0px;
  z-index: -3px;
}

.Error_error__1uIRr div {
  padding: 0px;

  box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.LowerSection_table-wrapper__3qjuY {
  overflow-y: auto;
  overflow-x: hidden;
  height: 94%;
  width: 100%;
}

.LowerSection_dropdown__3A2hx {
  width: "50px";
  font-size: small;
}

.LowerSection_table__1Cq44 thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.BufferStock_bufferstock-wrapper__nOPEQ {
  width: 100%;
  position: relative;
}

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}

.AvailableStockList_main-div-stocklist__1vXJW {
  font-family: "Lato", sans-serif;
  height: 88%;
  overflow: auto;
  width: 100%;
}

.AvailableStockList_tableHeader__QJ0AO,
.AvailableStockList_tableData__2-Efq {
  display: flex;
  font-size: 0.9em;
  width: 100%;
  text-align: center;
}

.AvailableStockList_tableHeader__QJ0AO {
  font-weight: 800;
  color: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.AvailableStockList_tableData__2-Efq {
  font-weight: 500;
  color: #77798c;
  color: var(--font-color);
}

.AvailableStockList_tableHeader__QJ0AO div {
  border-bottom: 1px solid #e8e8e8;
  border-bottom: 1px solid var(--border-color);
  border-left: 1px solid #e8e8e8;
  border-left: 1px solid var(--border-color);
  background-color: #ee4949;
  background-color: var(--theme-color);
  padding: 6px 0 6px 0;
}
.AvailableStockList_tableData__2-Efq div {
  padding: 8px 0 8px 0;
  border-left: 1px solid white;
}

.AvailableStockList_main-div-stocklist__1vXJW > div:nth-child(even) {
  background-color: #e4e4e4;
}

.AvailableStockList_tableData__2-Efq div:nth-child(2),
.AvailableStockList_tableData__2-Efq div:nth-child(7),
.AvailableStockList_tableData__2-Efq div:nth-child(10),
.AvailableStockList_tableData__2-Efq div:nth-child(11),
.AvailableStockList_tableData__2-Efq div:nth-child(12) {
  font-size: 1.1em;
  font-weight: 800;
}

.AvailableStockList_tableData__2-Efq div:nth-child(6) {
  cursor: pointer;
}

.AvailableStockList_tableData__2-Efq div:nth-child(13) {
  cursor: pointer;
}

.AvailableStockList_tableData__2-Efq div:nth-child(14) {
  cursor: pointer;
}

.AvailableStockList_modalbutton__1sqOW {
  cursor: pointer;
}

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}

.Search_wrapper__1lm6- {
  display: flex;
  height: 12%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 0 2% 0;
}
.Search_wrapper__1lm6- div {
  /* display: inline-block; */
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  align-items: flex-start;
}

.Search_wrapper__1lm6- div span {
  font-size: 1.1em;
  color: #161f6a;
  color: var(--bluish-font-color);
  font-weight: 400;
  margin-bottom: 1%;
}

.Search_wrapper__1lm6- button {
  position: relative;
  margin: 0;
  width: auto;
  background-color: transparent;
}
.Search_wrapper__1lm6- .Search_button__s0_gQ {
  position: absolute;
  bottom: 3px;
  cursor: pointer;
  margin-top: 10px;
  width: 30px;
  height: 30px;
  fill: black;
}

.Search_catg__XyZLv,
.Search_subcatg__1Ez5P,
.Search_product__1IJ1R,
.Search_choice__3oisQ {
  height: 22px;
}

.Search_catg__XyZLv {
  width: 16em;
}
.Search_subcatg__1Ez5P {
  width: 15em;
}
.Search_product__1IJ1R {
  width: 15em;
}
.Search_choice__3oisQ {
  width: 10em;
}

.AvailableStock_wrapper__3pDcj {
  height: 100%;
  position: relative;
}

.AvailableStock_forwardbtn__1a1XL {
  background-color: green;
  cursor: pointer;
  width: 12em;
  margin-top: 0;
  border-radius: "5px";
  position: absolute;
  top: 0;
  font-size: medium;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 2px 2px 4px lightgreen;
}

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}

.StockPopupChildren_wrapper__3s0xe {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 30vw;
  border-radius: 5px;
  padding: 2% 5%;
}
.StockPopupChildren_wrapper__3s0xe div {
  display: flex;
}

.StockPopupChildren_wrapper__3s0xe div div {
  width: 50%;
  justify-content: center;
  border: 1px solid black;
  padding: 1%;
}

.StockPopupChildren_wrapper__3s0xe div div:first-child {
  width: 50%;
  justify-content: center;
  color: #0d1136;
  color: var(--header-color);
  font-weight: 400;
  font-size: 1.1em;
}

.StockPopupChildren_wrapper__3s0xe div div:last-child {
  width: 50%;
  justify-content: center;
  font-weight: 300;
}
.StockPopupChildren_wrapper__3s0xe div:first-child div:first-child {
  width: 100%;
  justify-content: center;
  color: #0d1136;
  color: var(--header-color);
  font-weight: 400;
  border: none;
  font-size: 1.1em;
}
.StockPopupChildren_wrapper__3s0xe div button {
  width: 30%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 2%;
  cursor: pointer;
  border-radius: 5px;
}

.StockPopupChildren_wrapper__3s0xe > div:last-child {
  justify-content: end;
}

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --font-color1: #777777;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
  --border-color1: #ddd;
  --textbox-bg-color: #f9f9f9;
  --header-bg-color1: #333333;
  --header-bg-color2: #002933;
  --header-bg-color3: #36304a;
}

.LiveStock_Filter_livestock-filter-wrapper__33fd5 {
  width: 100%;
  font-family: "Poppins" sans-serif;
  height: 15%;
}

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --font-color1: #777777;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
  --border-color1: #ddd;
  --textbox-bg-color: #f9f9f9;
  --header-bg-color1: #333333;
  --header-bg-color2: #002933;
  --header-bg-color3: #36304a;
}

.LiveStock_List_livestock-table-wrapper__CKAOf {
  font-family: "Poppins", sans-serif;
  padding: 0 2px 0 3px;
  height: 85%;
}

.LiveStock_List_livestock-table-wrapper__CKAOf .LiveStock_List_tab__1EoW7 {
  width: 100%;
  height: 5%;
  margin-bottom: 1%;

  display: flex;
}

.LiveStock_List_livestock-table-wrapper__CKAOf .LiveStock_List_tab__1EoW7 > div {
  border: 1px solid #9b59b6;
  display: flex;
  width: 14%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 1em;
}

.LiveStock_List_rowswrapper__1Kf2g {
  overflow-y: scroll;
  height: 85%;
}

.LiveStock_List_tablehead__Eoh_x,
.LiveStock_List_tablerow__6JNqw {
  display: flex;
  flex-direction: row;
  font-size: 10px;
  min-height: 4vh;
}

.LiveStock_List_tablehead__Eoh_x {
  font-weight: 600;
  word-wrap: break-word;
  background-color: #333333;
  background-color: var(--header-bg-color1);
  color: white;
  min-height: 5vh;
}

.LiveStock_List_tablerow__6JNqw {
  font-weight: 500;
}

.LiveStock_List_tablehead__Eoh_x > span,
.LiveStock_List_tablerow__6JNqw > span {
  align-self: center;
}

.LiveStock_List_tablerow__6JNqw > span:nth-last-child(3),
.LiveStock_List_tablerow__6JNqw > span:nth-child(3) {
  font-weight: 700;
}

.LiveStock_List_tablerow__6JNqw:nth-child(odd) {
  background-color: #0000000d;
}

.LiveStock_List_editspan__3I7cp,
.LiveStock_List_deletespan__3sXlR {
  text-align: center;
  cursor: pointer;
}

.LiveStock_List_deleteicon__olWPL {
  width: 25%;
  height: 25%;
}

.LiveStock_List_editicon__39ZTE {
  width: 60%;
  height: 60%;
}

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --font-color1: #777777;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
  --border-color1: #ddd;
  --textbox-bg-color: #f9f9f9;
  --header-bg-color1: #333333;
  --header-bg-color2: #002933;
  --header-bg-color3: #36304a;
}

.Edit_editwrapper__7NmW1 {
  z-index: 50;
}

/* .livestock-edit-wrapper {
  display: flex;
  flex-direction: column;
  line-height: 2;
  margin: -2px;
  position: relative;
} */

/* .livestock-edit-wrapper > div {
  height: 3vh;
} */

/* .livestock-edit-wrapper > div > span:nth-child(1) {
  width: 35%;
  display: inline-block;
  font-size: 14px;
}

.livestock-edit-wrapper > div > span:nth-child(2),
.livestock-edit-wrapper > div .textbox {
  width: 65%;
  display: inline-block;
  font-size: 12px;
} */

/* .livestock-edit-wrapper > div .textbox {
  border: 1px solid var(--border-color1);
} */

/* .closebutton {
  position: absolute;
  top: 0px;
  right: -8px;
  color: var(--font-color);
  cursor: pointer;
  width: 15%;
  height: 15%;
} */

/* .button-wrapper {
  display: flex;
  flex-direction: row;
  background-color: yellow;
  align-items: flex-end;
}

.livestock-edit-wrapper .button-wrapper button {
  width: fit-content;
  border-radius: 0;
  min-height: 50%;
} */

.LiveStock_livestock-main-wrapper__1ok0z {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}

.UserActivity_container__FFXGh {
  margin: 0;
  box-sizing: border-box;
  height: 100vh;
}

.UserActivity_container__FFXGh nav {
  height: 10%;
  width: 100vw;
  position: -webkit-sticky;
  position: sticky;
}

.UserActivity_aside__1_jr0 {
  margin: 0;
  float: left;
  height: 90%;
  width: 18%;
  position: relative;
  overflow: auto;
  background-color: #0d1136;
}

.UserActivity_aside-position__3QqKe {
  position: absolute;
  background-color: transparent;
  overflow: hidden;
}

.UserActivity_side-menu__3OdF8 {
  transition: 2s;
}

.UserActivity_side-menu-open__2QY-b {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.UserActivity_side-menu-close__1MsBR {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.UserActivity_sidemenu-icon__7V6fU {
  position: absolute;
  top: 50%;
  width: auto;
  height: 4%;
  border: 1px solid #ee4949;
  color: #ee4949;
  cursor: pointer;
  z-index: 1;
}

.UserActivity_main__1RrYW {
  float: left;
  height: 90%;
  width: 82%;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.UserActivity_main-fullwidth__1Wp9i {
  width: 100%;
  transition: 2s;
}

/* width
::-webkit-scrollbar {
  width: 0.3rem;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: yellow;
  border-radius: 10px;
} */

/* ::-webkit-scrollbar-thumb:hover {
  background: #f2f2f2;
} */

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --font-color1: #777777;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
  --border-color1: #ddd;
  --textbox-bg-color: #f9f9f9;
}

.AddSupplier_add-supplier-wrapper__2kuaz {
  background-color: white;
  width: 30vw;
  font-family: "Lato", sans-serif;
  border-radius: 5px;
  padding: 3% 1% 1% 1%;
}
.AddSupplier_add-supplier-wrapper__2kuaz > div {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ddd;
  border-bottom: 1px solid var(--border-color1);
}

.AddSupplier_add-supplier-wrapper__2kuaz > div div:nth-child(1) {
  width: 50%;
  text-align: left;
  padding: 1% 0 1% 5%;
  font-weight: 600;
  color: #161f6a;
  color: var(--bluish-font-color);
}

.AddSupplier_add-supplier-wrapper__2kuaz > div div:nth-child(2) {
  width: 50%;
  padding: 1% 0;
  text-align: left;
}

.AddSupplier_add-supplier-wrapper__2kuaz > div div:nth-child(2) .AddSupplier_input1__b4c1- {
  width: 90%;
  height: 25px;
  border-radius: 3px;
  border: 1px solid #ddd;
  border: 1px solid var(--border-color1);
  background-color: #f9f9f9;
  background-color: var(--textbox-bg-color);
}

.AddSupplier_add-supplier-wrapper__2kuaz > div div:nth-child(2) .AddSupplier_input1__b4c1-:focus {
  outline: 0.5px solid #ee4949;
  outline: 0.5px solid var(--theme-color);
}

.AddSupplier_add-supplier-wrapper__2kuaz > div:nth-last-child(1) div:nth-child(1) {
  width: 63%;
  text-align: right;
  padding: 3% 2% 3% 0;
}

.AddSupplier_add-supplier-wrapper__2kuaz > div:nth-last-child(1) div:nth-child(2) {
  width: 37%;
  padding: 3% 0 3% 0;
}

.AddSupplier_add-supplier-wrapper__2kuaz .AddSupplier_close__3Xh6N {
  margin: 0;
  width: 120px;
  cursor: pointer;
  background-color: transparent;
  color: #777777;
  color: var(--font-color1);
  border: 0.4px solid #ddd;
  border: 0.4px solid var(--border-color1);
  box-shadow: 2px 2px 2px #e8e8e8;
  box-shadow: 2px 2px 2px var(--border-color);
}

.AddSupplier_add-supplier-wrapper__2kuaz .AddSupplier_add__1jrmD {
  margin: 0;
  width: 140px;
  cursor: pointer;
}

.AddSupplier_add-supplier-wrapper__2kuaz .AddSupplier_add__1jrmD:hover {
  border: 1px solid #e8e8e8;
  border: 1px solid var(--border-color);
}

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}

.SearchCriteria_supplier-criteria-wrapper__TXPpV {
}

.SearchCriteria_supplier-criteria-wrapper__TXPpV > div:nth-child(1) {
  text-align: right;
}
.SearchCriteria_supplier-criteria-wrapper__TXPpV .SearchCriteria_wrapper__RSftt {
  display: flex;
  height: 12%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 0 2% 0;
}

.SearchCriteria_supplier-criteria-wrapper__TXPpV > div:nth-child(1) button {
  width: 15%;
  cursor: pointer;
  margin: 10px 10px 4px 0;
}

.SearchCriteria_supplier-criteria-wrapper__TXPpV .SearchCriteria_wrapper__RSftt div {
  /* display: inline-block; */
  display: flex;
  flex-direction: column;
  /* margin-left: 10px; */
  align-items: flex-start;
}

.SearchCriteria_supplier-criteria-wrapper__TXPpV .SearchCriteria_wrapper__RSftt > div div {
  display: flex;
  flex-direction: row;
  padding-right: 1em;
}
.SearchCriteria_supplier-criteria-wrapper__TXPpV .SearchCriteria_wrapper__RSftt .SearchCriteria_checkbox__yLPts {
  margin-top: 2px;
  width: 30px;
  height: 18px;
}

.SearchCriteria_supplier-criteria-wrapper__TXPpV .SearchCriteria_wrapper__RSftt div span {
  font-size: 1.1em;
  color: #161f6a;
  color: var(--bluish-font-color);
  font-weight: 400;
  margin-bottom: 1%;
}

.SearchCriteria_supplier-criteria-wrapper__TXPpV .SearchCriteria_wrapper__RSftt button {
  position: relative;
  margin: 0;
  width: auto;
  background-color: transparent;
}
.SearchCriteria_supplier-criteria-wrapper__TXPpV .SearchCriteria_wrapper__RSftt .SearchCriteria_button__nq4CL {
  position: absolute;
  bottom: 3px;
  cursor: pointer;
  margin-top: 10px;
  width: 30px;
  height: 30px;
  fill: black;
}

.SearchCriteria_dropdown__2HX7W {
  width: 15em;
  height: 22px;
}

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}

.SupplierListing_main-div-stocklist__2uhGY {
  font-family: "Lato", sans-serif;
  height: 88%;
  overflow: auto;
  width: 100%;
}

.SupplierListing_tableHeader__-0aNV,
.SupplierListing_tableData__3p1LG {
  display: flex;
  font-size: 0.9em;
  width: 100%;
  text-align: center;
}

.SupplierListing_tableHeader__-0aNV {
  font-weight: 800;
  color: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.SupplierListing_tableData__3p1LG {
  font-weight: 500;
  color: #77798c;
  color: var(--font-color);
}

.SupplierListing_tableHeader__-0aNV div {
  border-bottom: 1px solid #e8e8e8;
  border-bottom: 1px solid var(--border-color);
  border-left: 1px solid #e8e8e8;
  border-left: 1px solid var(--border-color);
  background-color: #ee4949;
  background-color: var(--theme-color);
  padding: 6px 0 6px 0;
}
.SupplierListing_tableData__3p1LG div {
  padding: 8px 0 8px 0;
  border-left: 1px solid white;
}

.SupplierListing_main-div-stocklist__2uhGY > div:nth-child(even) {
  background-color: #e4e4e4;
}

.SupplierListing_tableData__3p1LG div:nth-child(2),
.SupplierListing_tableData__3p1LG div:nth-child(7),
.SupplierListing_tableData__3p1LG div:nth-child(10),
.SupplierListing_tableData__3p1LG div:nth-child(11),
.SupplierListing_tableData__3p1LG div:nth-child(12) {
  font-size: 1.1em;
  font-weight: 800;
}

.SupplierListing_tableData__3p1LG div:nth-child(15) .SupplierListing_editicon__1HpFy,
.SupplierListing_tableData__3p1LG div:nth-child(16) .SupplierListing_deleteicon__i22ZL {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.SupplierListing_tableData__3p1LG div:nth-child(6) {
  cursor: pointer;
}

.SupplierListing_tableData__3p1LG div:nth-child(13) {
  cursor: pointer;
}

.SupplierListing_tableData__3p1LG div:nth-child(14) {
  cursor: pointer;
}

.Supplier_wrapper__2YNeC {
  height: 100%;
  position: relative;
}

.Footer_footer-wrapper__164LQ {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 10px;
}

.Footer_footer-wrapper__164LQ h4 {
  font-size: 16px;
}

.Footer_copyright__7FClQ {
  border-top: 1px solid #ddd;
  color: #777777;
}

.Footer_copyright__7FClQ p {
  text-align: center;
  font-size: 0.9rem;
  font-weight: 400;
  padding: 30px 0 20px;
}

.Footer_icon-contact-information__3OyF4 {
  margin-right: 6px;
  position: relative;
  top: 2px;
}

.Footer_ull__fdgd_ {
  list-style: none;
  padding-left: 0;
  padding-bottom: 10px;
  font-weight: 400;
  color: #777;
}

p {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 700;
}

@media only screen and (max-width: 400px) {
  /* .footer-wrapper {
    text-align: center;
  } */
}

/* @font-face {
  font-family: myFirstFont;
  src: url("~/public/fonts/Niconne-Regular.ttf");
} */

.Header_header-wrapper__2JBgr {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

/* Hambergur Menu */
.Header_hamburger-menu-wrapper__3glf- {
  height: 100%;
  width: 5%;
  display: none;
  position: relative;
  overflow: auto;
}

.Header_hamburger-menu__29mQ1 {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  transition-property: fill;
  transition: 0.7s;
}

.Header_hamburger-menu__29mQ1:hover {
  fill: #ff4c3b;
}

/* company logo */
.Header_logo-div__Q-R1Q {
  height: 100%;
  width: 20%;
  position: relative;
}

.Header_logo-div__Q-R1Q img {
  width: 150px;
  height: 40px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

/* .tiplus {
  position: relative;
  top: -10px;
  left: 2px;
  fill: #ee4949;
  height: 20px;
  width: 20px;
} */

/* SEARCH  CSS */

.Header_search-div-navbar__3gZFc {
  width: 55%;
  height: 100%;
}

/* HEADER MANAGE ACCOUNTS ICON CSS */
.Header_navbar-icons-wrapper__CKUZY {
  width: 25%;
  list-style: none;
  height: 100%;
  margin: 0;
  text-align: right;
  padding: 0;
}

.Header_navbar-icons-wrapper__CKUZY li {
  display: inline-block;
  padding-top: 12px;
}

/* .icon-cart-wrapper {
   border: 1px solid black; 
}  */

.Header_icon-cart-wrapper__f6M6Y:hover {
  cursor: pointer;
}

.Header_icon-notification__IxyJ_,
.Header_icon-cart__2bMW1,
.Header_icon-manage-accounts__OahGw {
  position: relative;
  top: 12px;
  width: 2em;
  height: 2.2em;
  cursor: pointer;
}

.Header_icon-notification__IxyJ_ + div,
.Header_icon-cart__2bMW1 + div {
  display: inline;
  position: relative;
  left: -16px;
  top: -10px;
}

.Header_icon-manage-accounts__OahGw + span {
  font-size: 14px;
  font-weight: 200;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 500px) {
  .Header_hamburger-menu-wrapper__3glf- {
    display: inline-block;
    width: 10%;
  }

  .Header_search-div-navbar__3gZFc {
    width: 100%;
    display: block;
    height: 8vh;
    float: left;
    padding-left: 0px;
  }

  /* .logo-div {
    display: inline-block;
    height: 100%;
    width: 20%;
    overflow: auto;
    position: relative;
    top: 14px;
  } */

  .Header_logo-div__Q-R1Q {
    width: 30%;
    padding: 0;
    position: relative;
    overflow: auto;
  }

  .Header_logo-div__Q-R1Q img {
    width: 80%;
    height: 50%;
  }

  .Header_search-div-navbar__3gZFc {
    height: 70%;
  }
  .Header_icon-manage-accounts__OahGw + span {
    display: none;
  }

  #Header_navbar-icons-wrapper__CKUZY {
    width: 60%;
  }
}

.Navigation_backdrop__1vDte {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0.5;
  width: 100%;
  height: 100vh;
  z-index: 30;
  left: 0;
}

#Navigation_navigation-menus-wrapper-background-div__2cT18 {
  position: fixed;
  top: 0;
  height: 100%;
  width: 300px;
  display: inline-block;
  background-color: #ffffff;
  z-index: 32;
  transition-property: left;
  transition: 800ms;
  /* animation: slide-right 400ms ease-out forwards; */
}

.Navigation_navigation-menus-wrapper-ul__3OVWj {
  left: 0;
  list-style-type: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  min-width: 18vw;
}

.Navigation_navigation-menus-wrapper-ul__3OVWj li {
  padding-left: 60px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: large;
  margin-bottom: 30px;
}

.Navigation_user-image-name-li__3ynrz {
  height: 6em;
  background-color: rgb(247, 247, 247);
  display: block;
  position: relative;
}

.Navigation_navigation-menus-cross__1m-F8 {
  cursor: pointer;
  height: 28px;
  width: 28px;
  position: absolute;
  right: 0;
  top: 0;
  display: block;
}

.Navigation_profile-pic__yFO2W {
  width: 3em;
  height: 3em;
  margin-top: 0px;
}

.Navigation_profile-pic-div__DwGOj {
  height: 100%;
  padding-top: 20px;
}

.Navigation_user-image-name-li-div__xaIRb {
  height: 100%;
}

.Navigation_user-image-name-li-div__xaIRb div {
  display: inline-block;
  overflow: auto;
}

.Navigation_name-mob-div__3ElqE {
  height: 100%;
  padding-top: 28px;
  padding-left: 12px;
}

.Navigation_name-mob-div__3ElqE .Navigation_mobile__shPMT {
  font-size: 15px;
  color: red;
  font-weight: 700;
}
.Navigation_name-mob-div__3ElqE p {
  margin: 0;
}

@-webkit-keyframes Navigation_slide-right__3nubC {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-30rem);
            transform: translateX(-30rem);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes Navigation_slide-right__3nubC {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-30rem);
            transform: translateX(-30rem);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
} ;

:root {
  --theme-color: #ee4949;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
}

.Login_signuptext__1btHm,
.Login_forgetpwdtext__d3rs6 {
  font-weight: 400;
  color: #77798c;
  color: var(--font-color);
  text-align: center;
}

.Login_forgetpwdtext__d3rs6 {
  text-align: right;
  font-weight: 700;
}
.Login_forgetpwdtext__d3rs6:hover {
  text-decoration: underline;
  cursor: pointer;
}

.Login_signuptext__1btHm {
  margin-top: 4%;
  margin-bottom: 2%;
}

.Login_signuptext__1btHm u {
  color: #ee4949;
  color: var(--theme-color);
  font-weight: 600;
  cursor: pointer;
}

:root {
  --theme-color: #ee4949;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
}

.CommonInterface_login-wrapper__bIFI1 {
  display: flex;
  flex-direction: column;
  position: relative;
  -webkit-animation: CommonInterface_slidedown__3-WO_ 300ms ease-out forwards;
          animation: CommonInterface_slidedown__3-WO_ 300ms ease-out forwards;
  width: 500px;
  padding: 4% 10% 4% 10%;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 14%;
  text-align: left;
}

.CommonInterface_close__39ZIR {
  cursor: pointer;
  position: absolute;
  right: -32px;
  top: -32px;
  color: white;
  height: 35px;
  width: 35px;
  z-index: 32;
}

.CommonInterface_logintext__2S-6T {
  margin-bottom: 5%;
  color: #ee4949;
  color: var(--theme-color);
  font-size: 2em;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.CommonInterface_login-wrapper__bIFI1 input {
  height: 46px;
  margin-top: 1%;
  border-radius: 4px;
  border-style: none;
  background-color: #f9f9f9;
  border: 0.4px solid #ddd;
  font-weight: 400;
  font-size: 17px;
  color: #77798c;
  color: var(--font-color);
  padding-left: 4%;
}

.CommonInterface_emailtext__2km5n,
.CommonInterface_pwdtext__2O8Rk {
  font-weight: 700;
  font-size: 17px;
  color: #161f6a;
  color: var(--bluish-font-color);
}

.CommonInterface_login-wrapper__bIFI1 input:focus {
  border-style: none;
  border: 1px solid #ee4949;
  border: 1px solid var(--theme-color);
}

button {
  height: 50px;
  background-color: #ee4949;
  background-color: var(--theme-color);
  border-style: none;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  margin-top: 5%;
  border-radius: 4px;
  font-family: "Lato", sans-serif;
}

.CommonInterface_error__BLBEp {
  color: #ff4c4c;
  font-weight: 400;
  margin-bottom: 4%;
  text-align: right;
}

@-webkit-keyframes CommonInterface_slidedown__3-WO_ {
  from {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes CommonInterface_slidedown__3-WO_ {
  from {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.IconNotificationCount_icon-circle-wrapper__343IM {
  display: inline-block;
  text-align: center;
  position: relative;
  width: 1.6em;
  height: 1.6em;
  margin: 0;
}

.IconNotificationCount_icon-circle__2I6vZ {
  width: 100%;
  height: 100%;
  color: #ff4c3b;
}

.IconNotificationCount_icon-circle__2I6vZ + span {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 12px;
  font-weight: 500;
  color: white;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

:root {
  --theme-color: #ee4949;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
}

.Signup_loginText__lD69C {
  color: #77798c;
  color: var(--font-color);
  font-weight: 400;
  text-align: center;
  margin-top: 5%;
  cursor: pointer;
}

.Signup_loginText__lD69C u {
  color: #ee4949;
  color: var(--theme-color);
  font-weight: 600;
}

.Search_search-form__SEW2q {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: relative; */
}
.Search_search-button-wrapper__2plih {
  height: 66%;
  width: 80%;
  display: flex;
  align-items: center;
  border: 2px solid #ddd;
  border-radius: 6px;
}

.Search_input__2QmNl {
  width: 92%;
  height: 100%;
  font-size: medium;
  margin: 0;

  /* border-style: none; */
  border: none;
}

.Search_input__2QmNl:focus {
  border: none;
}

.Search_searchbutton__1PWIg {
  height: 100%;
  width: 60px;
  background-color: #f9f9f9;
  padding: 14px;
  cursor: pointer;
  border-radius: 5px;
}

@media only screen and (max-width: 500px) {
  .Search_search-form__SEW2q button {
    width: 20%;
    font-size: 4vw;
  }
  .Search_search-button-wrapper__2plih {
    height: 100%;
  }
}

.Slider_wrapper__2dlPl {
  font-family: "Lato", sans-serif;
  overflow: auto;
  height: 100%;
  background-color: #d9e2e1;
  position: relative;
  width: 100%;
}

.Slider_leftarrow__3CP0u,
.Slider_rightarrow__1Lp6M {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 76px;
  height: 80px;
  background-color: #f3f3f3;
  opacity: 0.7;
  cursor: pointer;
}

.Slider_leftarrow__3CP0u {
  left: 0;
}
.Slider_rightarrow__1Lp6M {
  right: 0;

  /* transition: 4000s ease-in-out; */
}

.Slider_square-wrapper__--IAn {
  position: absolute;
  bottom: 10%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);

  width: 8%;
  /* display: flex; */
  justify-content: space-evenly;
}

.Slider_image-square__1mP3l,
.Slider_image-square-select__2qFcb {
  box-shadow: 1px 1px 1px #ddd;
  stroke: lightgray;
  stroke-width: 3px;
  color: #fff;
  height: 100%;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.Slider_image-square-select__2qFcb {
  color: #ee4949;
}

@media only screen and (max-width: 500px) {
  .Slider_leftarrow__3CP0u,
  .Slider_rightarrow__1Lp6M {
    width: 30px;
    height: 30px;
    z-index: 1;
  }
}

/* .wrapper {
  font-family: "Lato", sans-serif;
  overflow: auto;
  height: 100%;
  background-color: #fff;
  position: relative;
  transition: 6s;
} */

.SliderImages_wrapper__3I68V {
  display: flex;
  height: 100%;
  width: 100%;
}

.SliderImages_paragraph-div1__20tMm {
  width: 58%;
  font-weight: bold;

  display: block;
  color: #777;
  margin-left: 10%;
  font-weight: 700;
  /* padding-top: 5%; */
}

/* 
.para11 {
  text-align: center;
  font-size: 36px;
  margin: 0;
  margin-top: 56px;
  font-weight: 700;
}
.para12 {
  text-align: center;

  font-size: 28px;
  padding: 0px;
  margin: 0;

  margin-top: 10px;
  margin-bottom: 6px;
} */

.SliderImages_para1__37Mo- {
  text-align: center;
  font-size: 36px;
  margin: 0;
  margin-top: 56px;
  color: #222;
  font-weight: 700;
}
.SliderImages_para2__2w0jX,
.SliderImages_para3__1L-hT {
  text-align: center;
  display: inline-block;
  font-size: 28px;
  padding: 0px;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 6px;
  font-family: "Gowun Batang", serif;
}

.SliderImages_para3__1L-hT {
  display: inline-block;
}

/* .leftarrow,
.rightarrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 76px;
  height: 80px;
  padding: 0;
  background-color: #f3f3f3;
  opacity: 0.7;
  cursor: pointer;
}

.rightarrow {
  right: 0;
} */

.SliderImages_img1__2j62H,
.SliderImages_img2__qibKY {
  /* float: right; */
  width: 42%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 500px) {
  .SliderImages_paragraph-div1__20tMm {
    margin-top: 38px;
    line-height: 22px;

    opacity: 0.7;
    margin-left: 0;
    padding-top: 0;
  }

  .SliderImages_para1__37Mo- {
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 5%;
    margin-left: 12px;
    text-align: left;
    font-weight: 900;
    color: black;
  }
  .SliderImages_para2__2w0jX,
  .SliderImages_para3__1L-hT {
    display: block;
    text-align: left;
    font-size: 18px;
    color: black;

    /* border: 1px solid black; */
    margin: 0;
    margin-left: 30px;
  }

  .SliderImages_para3__1L-hT {
    margin-left: 42px;
    margin-top: 0;
  }

  .SliderImages_img1__2j62H,
  .SliderImages_img2__qibKY {
    height: 180px;
    width: 45%;
    object-fit: cover;
    opacity: 1;
  }
}

.RightAside_wrapper__2nmar {
  display: flex;
  height: 22vh;
  /* padding-top: 16px;
  padding-bottom: 16px; */
  align-items: center;
  font-family: "Lato", sans-serif;
}

.RightAside_exchange-div__1U08N,
.RightAside_shipping-div__2Z3qO,
.RightAside_sameday-div__3BYag {
  width: 33.33%;
  height: 50%;
  display: flex;
  align-items: center;

  justify-content: center;
}

.RightAside_icon__2-ubd {
  margin: 0;
  padding: 0;
  /* height: 6vh;
  width: 6vw; */
  /* border: 1px solid black; */
  width: 65px;
  height: 48px;

  color: #ff4c3b;
  stroke-width: 1px;
}

.RightAside_sameday-icon__1G5Ro {
  color: white;
  stroke: #ee4949;
}

.RightAside_div2__Xmjl2 {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.RightAside_para__2VKvA {
  display: inline-block;
}

.RightAside_para__2VKvA h4,
p {
  margin: 0;
  padding: 0;
}

.RightAside_para__2VKvA h4 {
  color: #212529;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 6px;
}

.RightAside_para__2VKvA p {
  color: #777;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 500px) {
  .RightAside_wrapper__2nmar {
    flex-direction: column;
    height: 60vh;
    width: 100%;
  }

  .RightAside_exchange-div__1U08N,
  .RightAside_shipping-div__2Z3qO,
  .RightAside_sameday-div__3BYag {
    flex-direction: column;
    width: 100%;
  }

  .RightAside_para__2VKvA h4 {
    text-align: center;
  }

  .RightAside_div2__Xmjl2 {
    border: none;
  }
}

.MenuIconsSvg_size__1AP6g {
  width: 60px;
  height: 60px;
  stroke-width: 10px;
}

@media only screen and (max-width: 500px) {
  .MenuIconsSvg_size__1AP6g {
    width: 50px;
    height: 50px;
    stroke-width: 15px;
  }
}

.MenuIconCard_icon-name-wrapper__3CdAM {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  height: 100%;
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.MenuIconCard_name__LG1IF {
  color: #212121;
  font-size: 14px;
  font-weight: 600;
  /* margin-top: 6px; */
}

@media only screen and (max-width: 500px) {
  .MenuIconCard_icon-name-wrapper__3CdAM {
    height: 16vh;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    align-items: center;
    justify-content: center;
  }
}

.ItemMenuIcons_MenuIconCard-wrapper__2ZKIb {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
}

.ItemMenuIcons_MenuIconCard__3whcS {
  box-shadow: 3px 3px 1px #efefef;
  background-color: #ffffff;
  width: 100%;
  height: 44%;
  cursor: pointer;
  overflow: auto;
  margin-bottom: 6%;
  border: none;
  border-radius: 6px;
}

.ItemMenuIcons_MenuIconCard-border__17cQN {
  border: 2px solid black;
}

@media only screen and (max-width: 500px) {
  .ItemMenuIcons_MenuIconCard-wrapper__2ZKIb {
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }
  .ItemMenuIcons_MenuIconCard__3whcS {
    width: 40%;
    margin-bottom: 0;
  }
}

.ItemCard_itemcard-wrapper__1tCbr {
  width: 260px;
  height: 340px;
  font-family: "Lato", sans-serif;
  display: flex;
  flex-direction: column;
  /* border: 1px solid black; */
  box-shadow: 2px 2px 5px #efefef;
  background-color: #fff;
  margin-left: 18px;
  margin-bottom: 18px;
  color: rgb(153, 153, 153);
  border-radius: 10px;
}

.ItemCard_itemcard-wrapper__1tCbr:hover {
  border: 1px solid #f9c8c8;
  cursor: pointer;
}

.ItemCard_item-discount__G-uK- {
  height: 7%;
  width: 100%;
  /* border: 1px solid black; */
  float: right;
  color: #ee4949;
  text-align: right;
  font-weight: 600;
}

.ItemCard_name__3SIgF p {
  font-size: 18px;
  margin-bottom: 2px;
  /* border: 1px solid black; */
}

.ItemCard_image-div__2ylEI {
  box-sizing: content-box;
  text-align: center;
  /* height: 160px; */
  height: 46%;
  margin-top: 2%;
}

img {
  width: 200px;
  height: 100%;
}

.ItemCard_itemProperties-combined-wrapper__1dyL0 {
  padding-left: 10px;
  padding-right: 10px;
  height: 43%;
  margin-top: 2%;
}

/*item name wrapper*/
.ItemCard_hindiName__3pRb7 {
  margin-top: 0.5%;
}

/*price wrapper*/

.ItemCard_price-wrapper__W8Xi8 {
  margin-top: 2%;
}
.ItemCard_mrp-text__10Dpz {
  font-weight: 600;
}
.ItemCard_mrp-value__3peBK {
  color: #0d1136;
  font-weight: 600;
}

.ItemCard_unit-wrapper__2ztWX {
  margin-top: 5%;
  /* border: 1px solid black; */
  display: flex;
}

.ItemCard_unit-inner-wrapper__3Ibvo {
  width: 60%;
}

.ItemCard_primaryUnit__13jcL,
.ItemCard_secondaryUnit__3fjRq {
  background-color: #ee4949;
  display: block;
  border-radius: 3px;
  height: 25px;
  text-align: center;
  color: white;
  font-weight: 400;
  cursor: pointer;
  /* padding-left: 8px;
  padding-right: 8px; */
  cursor: pointer;
  float: left;
  width: 100%;
}

.ItemCard_secondaryUnit__3fjRq {
  margin-top: 2%;
}

.ItemCard_minus__38Xuh,
.ItemCard_plus__1SRFN {
  width: 20%;
  height: 100%;
}

.ItemCard_minus__38Xuh:hover,
.ItemCard_plus__1SRFN:hover {
  background-color: #f16d6d;
}

.ItemCard_minus__38Xuh {
  float: left;
}

.ItemCard_plus__1SRFN {
  float: right;
}

.ItemCard_add__Xj8_b {
  width: 100%;
  display: inline-block;
}

.ItemCard_quantity-selected-wrapper__13MF1 {
  width: 40%;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  /* margin-left: 6px; */
  box-shadow: 2px 2px 5px #efefef;
}

.ItemCard_quantity-selected-wrapper__13MF1 span {
  color: #222;
}

.ItemCard_dummystyle__2hLyZ {
  background-color: transparent;
}

.Product_wrapper__2FlhX {
  display: flex;
  flex-wrap: wrap;
  box-shadow: 2px 2px 5px #efefef;
  padding-top: 5px;
  overflow: auto;
  background-color: #f9f9f9;
}

#CustomerActivity_header-div__2pfZv {
  height: 5em;
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  background-color: #fff;
  box-shadow: 1px 1px 1px #efefef;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 50;
}

#CustomerActivity_left-aside-div__hjRNN {
  height: 40%;
  width: 78%;
  float: left;
  background-color: #f9f9f9;
  /* border: 1px solid black; */
  margin-right: 3%;
  margin-left: 2%;
  margin-top: 18px;
  box-shadow: 1px 1px 1px #efefef;
}

#CustomerActivity_content-div__3k66u {
  height: 40%;
  width: 15%;
  float: left;
  margin-top: 18px;
  /* box-shadow: 1px 1px 1px #efefef; */
}

#CustomerActivity_right-aside-div__36X94 {
  width: 100%;
  float: left;
  padding-left: 2%;
  padding-right: 2%;
  margin-top: 22px;
  margin-bottom: 22px;
  background-color: #fff;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: 2px 2px 2px #efefef;
  z-index: 15;
}

#CustomerActivity_footer-component-div__2WN89 {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  float: left;
  background-color: #fff;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 500px) {
  #CustomerActivity_header-div__2pfZv {
    height: 4.5em;
    padding-left: 0.6%;
    padding-right: 0.6%;
  }

  #CustomerActivity_left-aside-div__hjRNN {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  #CustomerActivity_content-div__3k66u {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
  }

  #CustomerActivity_right-aside-div__36X94 {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background-color: #f9f9f9;
  font-weight: 200;
}

#bodycontainer {
  height: 100vh;
}

/* width */
head::-webkit-scrollbar {
  width: 10px;
}

/* Track */
head::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
head::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
head::-webkit-scrollbar-thumb:hover {
  background: #555;
}

