@import "../../customer-app/common-css/Lato-font.css";
@import "../../customer-app/common-css/Poppins-font.css";

:root {
  --theme-color: #ee4949;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
}

.city-wrapper {
  position: relative;
  /* border: 1px solid black; */

  display: flex;
  flex-direction: column;
}
.citylist {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 40;
  top: 72px;
  left: 3px;
  background-color: #f9f9f9;
  width: 50%;
  min-height: fit-content;
  max-height: 100px;
  overflow: auto;
  border: 1px solid #ddd;
  border-top: none;
  box-shadow: 1px 1px 0 #ddd;
}
.names {
  font-weight: 600;
  font-size: 17px;
  color: var(--bluish-font-color);
  text-align: left;
}
.span {
  text-align: left;
  padding-left: 8px;
  cursor: pointer;
  padding-top: 8px;
  font-weight: 400;
}

.span:hover {
  background-color: lightgray;
}
