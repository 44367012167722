.container {
  margin: 0;
  box-sizing: border-box;
  height: 100vh;
}

.container nav {
  height: 10%;
  width: 100vw;
  position: sticky;
}

.aside {
  margin: 0;
  float: left;
  height: 90%;
  width: 18%;
  position: relative;
  overflow: auto;
  background-color: #0d1136;
}

.aside-position {
  position: absolute;
  background-color: transparent;
  overflow: hidden;
}

.side-menu {
  transition: 2s;
}

.side-menu-open {
  transform: translateX(0);
}

.side-menu-close {
  transform: translateX(-100%);
}

.sidemenu-icon {
  position: absolute;
  top: 50%;
  width: auto;
  height: 4%;
  border: 1px solid #ee4949;
  color: #ee4949;
  cursor: pointer;
  z-index: 1;
}

.main {
  float: left;
  height: 90%;
  width: 82%;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.main-fullwidth {
  width: 100%;
  transition: 2s;
}

/* width
::-webkit-scrollbar {
  width: 0.3rem;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: yellow;
  border-radius: 10px;
} */

/* ::-webkit-scrollbar-thumb:hover {
  background: #f2f2f2;
} */
