:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}

.wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 30vw;
  border-radius: 5px;
  padding: 2% 5%;
}
.wrapper div {
  display: flex;
}

.wrapper div div {
  width: 50%;
  justify-content: center;
  border: 1px solid black;
  padding: 1%;
}

.wrapper div div:first-child {
  width: 50%;
  justify-content: center;
  color: var(--header-color);
  font-weight: 400;
  font-size: 1.1em;
}

.wrapper div div:last-child {
  width: 50%;
  justify-content: center;
  font-weight: 300;
}
.wrapper div:first-child div:first-child {
  width: 100%;
  justify-content: center;
  color: var(--header-color);
  font-weight: 400;
  border: none;
  font-size: 1.1em;
}
.wrapper div button {
  width: 30%;
  height: fit-content;
  padding: 2%;
  cursor: pointer;
  border-radius: 5px;
}

.wrapper > div:last-child {
  justify-content: end;
}
