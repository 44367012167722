@import "../../../../../customer-app/common-css/Lato-font.css";
@import "../../../../../customer-app/common-css/Poppins-font.css";

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}

.main-div-stocklist {
  font-family: "Lato", sans-serif;
  height: 88%;
  overflow: auto;
  width: 100%;
}

.tableHeader,
.tableData {
  display: flex;
  font-size: 0.9em;
  width: 100%;
  text-align: center;
}

.tableHeader {
  font-weight: 800;
  color: white;
  position: sticky;
  top: 0;
}

.tableData {
  font-weight: 500;
  color: var(--font-color);
}

.tableHeader div {
  border-bottom: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  background-color: var(--theme-color);
  padding: 6px 0 6px 0;
}
.tableData div {
  padding: 8px 0 8px 0;
  border-left: 1px solid white;
}

.main-div-stocklist > div:nth-child(even) {
  background-color: #e4e4e4;
}

.tableData div:nth-child(2),
.tableData div:nth-child(7),
.tableData div:nth-child(10),
.tableData div:nth-child(11),
.tableData div:nth-child(12) {
  font-size: 1.1em;
  font-weight: 800;
}

.tableData div:nth-child(6) {
  cursor: pointer;
}

.tableData div:nth-child(13) {
  cursor: pointer;
}

.tableData div:nth-child(14) {
  cursor: pointer;
}

.modalbutton {
  cursor: pointer;
}
