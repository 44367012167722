.orderdetail-filter-wrapper {
  height: 15%;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  display: flex;
  flex-direction: column;
}

.date-wrapper {
  height: 70%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.date-wrapper .range {
  font-size: 14px;
  font-weight: 500;
}

.from {
  font-size: 14px;
  font-weight: 300;
}
.orderdetail-filter-wrapper .searchdiv {
  width: 10%;
}
.orderdetail-filter-wrapper .searchdiv .searchButton {
  width: 25%;
  height: 25%;
  cursor: pointer;
}

.orderdetail-filter-wrapper .tabs {
  color: black;
  font-weight: 500;
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: row;
}

.tabs div {
  height: 100%;
  width: 15%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #9b59b6;
}
