.background {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100vh;
  z-index: 25;
  left: 0;
  top: 0;
}

.modal {
  z-index: 30;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
}
