@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.icon-name-wrapper {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  height: 100%;
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.name {
  color: #212121;
  font-size: 14px;
  font-weight: 600;
  /* margin-top: 6px; */
}

@media only screen and (max-width: 500px) {
  .icon-name-wrapper {
    height: 16vh;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    align-items: center;
    justify-content: center;
  }
}
