@import "../../../customer-app/common-css/Poppins-font.css";

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --font-color1: #777777;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
  --border-color1: #ddd;
  --textbox-bg-color: #f9f9f9;
  --header-bg-color1: #333333;
  --header-bg-color2: #002933;
  --header-bg-color3: #36304a;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 400;
  background-color: rgba(0, 0, 0, 0.75);
}

.editmodal {
  position: fixed;
  top: 7vh;
  left: 0;
  width: 100%;
  background-color: transparent;
  z-index: 401;
  animation: slide-down 300ms ease-out forwards;
  font-family: "Poppins", sans-serif;
  text-align: left;
}

.editmodal .content {
  border-radius: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 35%;
  padding: 1rem;
  line-height: 2.8;
  position: relative;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.editmodal .content span:nth-child(1),
.editmodal .content span:nth-child(2) {
  display: inline-block;
}

.editmodal .content span:nth-child(1) {
  width: 40%;
  font-weight: 600;
}
.editmodal .content span:nth-child(2) {
  width: 60%;
  font-weight: 400;
}

.editmodal .content input {
  width: 30%;
  border: 1px solid var(--border-color);
  outline-color: var(--theme-color-shade2);
  height: 30px;
  /* border: 1px solid var(--font-color); */
}

.editmodal .closebutton {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--font-color);
  cursor: pointer;
  width: 15%;
  height: 15%;
}

.editmodal .content .button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 3%;
}

.editmodal .content .button-wrapper button {
  width: 10rem;
  height: 40px;
  margin: 0;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--theme-color-shade1);
}

.editmodal .button-wrapper button:nth-child(1) {
  margin-right: 30px;
}
.editmodal .button-wrapper button:nth-child(2) {
  background-color: transparent;
  color: var(--theme-color);
  border: 1px solid var(--theme-color);
}

.editmodal .button-wrapper button:nth-child(1):hover {
  background-color: transparent;
  color: var(--theme-color);
  border: 1px solid var(--theme-color);
}

.editmodal .button-wrapper button:nth-child(2):hover {
  border: 2px solid var(--theme-color);
}

@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
