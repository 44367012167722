/* @font-face {
  font-family: myFirstFont;
  src: url("~/public/fonts/Niconne-Regular.ttf");
} */

.header-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

/* Hambergur Menu */
.hamburger-menu-wrapper {
  height: 100%;
  width: 5%;
  display: none;
  position: relative;
  overflow: auto;
}

.hamburger-menu {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition-property: fill;
  transition: 0.7s;
}

.hamburger-menu:hover {
  fill: #ff4c3b;
}

/* company logo */
.logo-div {
  height: 100%;
  width: 20%;
  position: relative;
}

.logo-div img {
  width: 150px;
  height: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/* .tiplus {
  position: relative;
  top: -10px;
  left: 2px;
  fill: #ee4949;
  height: 20px;
  width: 20px;
} */

/* SEARCH  CSS */

.search-div-navbar {
  width: 55%;
  height: 100%;
}

/* HEADER MANAGE ACCOUNTS ICON CSS */
.navbar-icons-wrapper {
  width: 25%;
  list-style: none;
  height: 100%;
  margin: 0;
  text-align: right;
  padding: 0;
}

.navbar-icons-wrapper li {
  display: inline-block;
  padding-top: 12px;
}

/* .icon-cart-wrapper {
   border: 1px solid black; 
}  */

.icon-cart-wrapper:hover {
  cursor: pointer;
}

.icon-notification,
.icon-cart,
.icon-manage-accounts {
  position: relative;
  top: 12px;
  width: 2em;
  height: 2.2em;
  cursor: pointer;
}

.icon-notification + div,
.icon-cart + div {
  display: inline;
  position: relative;
  left: -16px;
  top: -10px;
}

.icon-manage-accounts + span {
  font-size: 14px;
  font-weight: 200;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 500px) {
  .hamburger-menu-wrapper {
    display: inline-block;
    width: 10%;
  }

  .search-div-navbar {
    width: 100%;
    display: block;
    height: 8vh;
    float: left;
    padding-left: 0px;
  }

  /* .logo-div {
    display: inline-block;
    height: 100%;
    width: 20%;
    overflow: auto;
    position: relative;
    top: 14px;
  } */

  .logo-div {
    width: 30%;
    padding: 0;
    position: relative;
    overflow: auto;
  }

  .logo-div img {
    width: 80%;
    height: 50%;
  }

  .search-div-navbar {
    height: 70%;
  }
  .icon-manage-accounts + span {
    display: none;
  }

  #navbar-icons-wrapper {
    width: 60%;
  }
}
