@import "../../../../customer-app/common-css/Lato-font.css";
@import "../../../../customer-app/common-css/Poppins-font.css";

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --font-color1: #777777;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
  --border-color1: #ddd;
  --textbox-bg-color: #f9f9f9;
}

.add-supplier-wrapper {
  background-color: white;
  width: 30vw;
  font-family: "Lato", sans-serif;
  border-radius: 5px;
  padding: 3% 1% 1% 1%;
}
.add-supplier-wrapper > div {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--border-color1);
}

.add-supplier-wrapper > div div:nth-child(1) {
  width: 50%;
  text-align: left;
  padding: 1% 0 1% 5%;
  font-weight: 600;
  color: var(--bluish-font-color);
}

.add-supplier-wrapper > div div:nth-child(2) {
  width: 50%;
  padding: 1% 0;
  text-align: left;
}

.add-supplier-wrapper > div div:nth-child(2) .input1 {
  width: 90%;
  height: 25px;
  border-radius: 3px;
  border: 1px solid var(--border-color1);
  background-color: var(--textbox-bg-color);
}

.add-supplier-wrapper > div div:nth-child(2) .input1:focus {
  outline: 0.5px solid var(--theme-color);
}

.add-supplier-wrapper > div:nth-last-child(1) div:nth-child(1) {
  width: 63%;
  text-align: right;
  padding: 3% 2% 3% 0;
}

.add-supplier-wrapper > div:nth-last-child(1) div:nth-child(2) {
  width: 37%;
  padding: 3% 0 3% 0;
}

.add-supplier-wrapper .close {
  margin: 0;
  width: 120px;
  cursor: pointer;
  background-color: transparent;
  color: var(--font-color1);
  border: 0.4px solid var(--border-color1);
  box-shadow: 2px 2px 2px var(--border-color);
}

.add-supplier-wrapper .add {
  margin: 0;
  width: 140px;
  cursor: pointer;
}

.add-supplier-wrapper .add:hover {
  border: 1px solid var(--border-color);
}
