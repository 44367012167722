@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.wrapper {
  display: flex;
  height: 22vh;
  /* padding-top: 16px;
  padding-bottom: 16px; */
  align-items: center;
  font-family: "Lato", sans-serif;
}

.exchange-div,
.shipping-div,
.sameday-div {
  width: 33.33%;
  height: 50%;
  display: flex;
  align-items: center;

  justify-content: center;
}

.icon {
  margin: 0;
  padding: 0;
  /* height: 6vh;
  width: 6vw; */
  /* border: 1px solid black; */
  width: 65px;
  height: 48px;

  color: #ff4c3b;
  stroke-width: 1px;
}

.sameday-icon {
  color: white;
  stroke: #ee4949;
}

.div2 {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.para {
  display: inline-block;
}

.para h4,
p {
  margin: 0;
  padding: 0;
}

.para h4 {
  color: #212529;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 6px;
}

.para p {
  color: #777;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 500px) {
  .wrapper {
    flex-direction: column;
    height: 60vh;
    width: 100%;
  }

  .exchange-div,
  .shipping-div,
  .sameday-div {
    flex-direction: column;
    width: 100%;
  }

  .para h4 {
    text-align: center;
  }

  .div2 {
    border: none;
  }
}
