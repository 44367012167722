.category-table {
  font-family: sans-serif;
  font-size: 1rem;
  text-align: left;
  width: 100%;
}

thead {
  background-color: #ee4949;
  color: #fff;
}

.thead-Id {
  width: 6rem;
}

.thead-name {
  width: 19rem;
}

.thead-nameHindi {
  width: 15rem;
}
.thead-Image {
  width: 10rem;
}

.thead-edit {
  /* width: 3rem; */
  background-color: #fff;
}

.thead-delete {
  width: 3rem;
  background-color: #fff;
}

tbody tr:nth-child(odd) {
  background-color: lightgrey;
}

tbody tr button {
  background: transparent;
  border: none;
  cursor: pointer;
  background-color: aqua;
  width: 100%;
  height: 2rem;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.edit,
.delete {
  width: 2rem;
  background-color: #fff;
}
