.table-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  height: 94%;
  width: 100%;
}

.dropdown {
  width: "50px";
  font-size: small;
}

.table thead {
  position: sticky;
  top: 0;
}
