.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem 0 1rem 0;
  background-color: lightgrey;
}

.innerform {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.label-input {
  display: flex;
  flex-direction: column;
}

.label-input label {
  text-align: left;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
}

.label-input input {
  height: 2.4rem;
  width: 14rem;
  outline-color: #ee4949;
  border: 0.1px solid gray;
}

.buttondiv button {
  width: 8rem;
  height: 3rem;
  background-color: #ee4949;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 400;
}

.buttondiv button:hover {
  background-color: #ec6e6e;
}
