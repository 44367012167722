@import "../../../../customer-app/common-css/Lato-font.css";
@import "../../../../customer-app/common-css/Poppins-font.css";

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}

.wrapper {
  /* border: 2px solid lightgray; */
  height: 100%;
  display: flex;
  font-family: "Lato", sans-serif;
  box-sizing: border-box;
}

.customerinfo {
  width: 64%;
  height: 100%;
  padding: 0.5% 2% 0 2%;
}

.invoiceinfo {
  width: 12%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5% 2% 0 0;
  margin: 0;
}

.otherdetails {
  width: 24%;
  height: 100%;
  border: 1px solid var(--border-color);
  padding: 0.5% 0;
  font-size: 0.9em;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.otherdetails > div {
  display: flex;
  background-color: #fff;
}

.otherdetails > div > div:nth-child(1) {
  width: 65%;
  text-align: left;
  color: var(--font-color);
  font-weight: 600;
}

.otherdetails > div > div:nth-child(2) {
  width: 35%;
  padding-left: 5px;
}
.otherdetails > div:nth-child(2) > div:nth-child(2) {
  display: inline-block;
  width: fit-content;
}
.invoiceinfo input[type="search"],
.customerinfo input[type="search"] {
  width: 100%;
  height: 40px;
  margin: 0;
  color: var(--font-color);
  font-size: 0.9em;
}

.invoicesearch-wrapper input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: 14px;
  cursor: pointer;
}

.invoiceNo input[type="number"],
.date input[type="date"] {
  width: 100%;
}

.searchcustomer-wrapper {
  margin-bottom: 1%;
  padding: 0 1%;
  display: flex;
}

.searchcustomer-wrapper .userId {
  width: 33%;
  color: var(--font-color);
  font-weight: 600;
  display: flex;
  align-items: center;
}

.searchcustomer-wrapper .searchoption {
  display: flex;
  color: var(--bluish-font-color);
  font-weight: 500;
  width: 25%;
}

.searchcustomer-wrapper .searchoption div {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ddd;
}

.searchcustomer-wrapper .searchcustomer {
  width: 42%;
}

.backgroundcolor {
  color: var(--white-color);
  background-color: var(--theme-color);
  border: groove;
}

.searchcustomer-wrapper .userId > span,
.invoiceNo > span,
.date > span,
.customerName > span,
.address1 > span,
.address2 > span,
.mobile > span,
.email > span,
.city > span,
.state > span,
.role > span {
  font-weight: 600;
  color: var(--bluish-font-color);
}
.date {
  display: flex;
  flex-direction: column;
  /* margin-top: 3%; */
}

.date input[type="date"] {
  cursor: pointer;
}

.customerName,
.mobile,
.email,
.address1,
.address2,
.city,
.state,
.role,
.customerinfo {
  display: flex;
  flex-direction: column;
}

.customerName,
.mobile,
.email,
.address1,
.address2,
.city,
.state,
.role,
.invoiceNo {
  margin-bottom: 6%;
}

.invoicesearch-wrapper {
  margin-bottom: 4%;
}

.name-address-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  text-align: left;
  position: relative;
}

/* .name-address-wrapper .customerName {
  width: 100%;
} */

.name-wrapper,
.address-wrapper {
  width: 23%;
  padding: 0 1%;
}

.mob-email-wrapper,
.city-state-wrapper {
  width: 20%;
  padding: 0 1%;
}

.role-wrapper {
  width: 20%;
  padding: 0 1%;
}

.customerName input,
.mobile input,
.email input,
.address1 input,
.address2 input,
.city input,
.state input,
.role .rolelist,
.date .datelist,
.invoiceNo input,
.invoiceinfo input[type="search"],
.customerinfo input[type="search"] {
  height: 34px;
  width: 100%;
  margin-top: 0;
}

.role .rolelist {
  color: white;
  background-color: #116d6e;
  font-weight: 600;
}

.customerName input,
.mobile input,
.email input,
.address1 input,
.address2 input,
.city input,
.state input {
  font-size: 1em;
}

.name-address-wrapper .addbuttonwrapper {
  align-self: flex-end;
  width: 99%;
  position: absolute;
  text-align: right;
}
.name-address-wrapper .addbuttonwrapper .addcustomer,
.name-address-wrapper .addbuttonwrapper .resetCustomer {
  height: 1.4em;
  width: 20%;
  cursor: pointer;
  color: #ee4949;
  border: 1px solid #f48a8a;
  background-color: transparent;
}

.name-address-wrapper .addbuttonwrapper .resetCustomer {
  width: 12%;
  margin-left: 4%;
}

.name-address-wrapper .addbuttonwrapper .addcustomer:hover,
.name-address-wrapper .addbuttonwrapper .resetCustomer:hover {
  color: white;
  background-color: #ee4949;
}
