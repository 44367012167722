@import "../../common-css/Lato-font.css";
@import "../../common-css/Poppins-font.css";

:root {
  --theme-color: #ee4949;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
}

.loginText {
  color: var(--font-color);
  font-weight: 400;
  text-align: center;
  margin-top: 5%;
  cursor: pointer;
}

.loginText u {
  color: var(--theme-color);
  font-weight: 600;
}
