.icon-circle-wrapper {
  display: inline-block;
  text-align: center;
  position: relative;
  width: 1.6em;
  height: 1.6em;
  margin: 0;
}

.icon-circle {
  width: 100%;
  height: 100%;
  color: #ff4c3b;
}

.icon-circle + span {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 12px;
  font-weight: 500;
  color: white;
  transform: translate(-50%, -50%);
}
