@import "../../../../customer-app/common-css/Lato-font.css";
@import "../../../../customer-app/common-css/Poppins-font.css";

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}

.wrapper {
  display: flex;
  flex-direction: column;
  /* position: fixed;
  top: 2%;
  left: 2%; */
  padding: 1% 1.5% 0 1.5%;
  font-family: "Lato", sans-serif;
  width: 30%;
  line-height: 1.5;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.wrapper > p {
  text-align: center;
  margin: 0;
  font-size: 10px;
}

.wrapper > span:nth-child(2),
.wrapper > span:nth-child(3),
.wrapper > span:nth-child(4) {
  text-align: center;
  font-size: 0.8em;
  font-weight: 600;
  color: var(--font-color);
}

.wrapper > span:nth-child(4) {
  font-size: 1em;
  color: var(--bluish-font-color);
}

.wrapper .customerinfo-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;

  background-color: #fff;
  font-weight: 300;
  font-size: 10px;
}

.customerinfo-wrapper .customerinfo1 {
  width: 60%;
}
.customerinfo-wrapper .customerinfo2 {
  width: 40%;
}

.customerinfo-wrapper .customerinfo1 > div > span:nth-child(1),
.customerinfo-wrapper .customerinfo1 > div > span:nth-child(2),
.customerinfo-wrapper .customerinfo2 > div > span:nth-child(1),
.customerinfo-wrapper .customerinfo2 > div > span:nth-child(2) {
  display: inline-block;
  /* border: 1px solid black; */
  font-size: 10px;
}

.customerinfo-wrapper .customerinfo1 > div > span:nth-child(1),
.customerinfo-wrapper .customerinfo2 > div > span:nth-child(1) {
  width: 35%;
}
.customerinfo-wrapper .customerinfo1 > div > span:nth-child(2),
.customerinfo-wrapper .customerinfo2 > div > span:nth-child(2) {
  width: 65%;
}

.wrapper .header {
  /* .wrapper > div:nth-child(7) > div { */
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
  padding: 3px 0;
  font-weight: 400;
  display: flex;
}
.wrapper .header > div {
  text-align: left;
  font-size: 10px;
}

.wrapper .header > div:nth-child(2) {
  padding-left: 4px;
}

.wrapper .itemsList {
  font-weight: 400;
  display: flex;
  font-size: 10px;
}

.parent-amounttdetails {
  position: absolute;
  bottom: 5%;
  right: 4%;
  width: 100%;
  line-height: 1;
}

.wrapper .amounttdetails > div:nth-child(1),
.wrapper .amounttdetails > div:nth-child(2),
.wrapper .amounttdetails > div:nth-child(3),
.wrapper .amounttdetails > div:nth-child(4) {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  text-align: right;
}

.wrapper .amounttdetails > div:nth-child(1) div:nth-child(1),
.wrapper .amounttdetails > div:nth-child(2) div:nth-child(1),
.wrapper .amounttdetails > div:nth-child(3) div:nth-child(1),
.wrapper .amounttdetails > div:nth-child(4) div:nth-child(1) {
  width: 80%;
  margin: 0;
  padding: 0;
}

.wrapper .amounttdetails > div:nth-child(1) div:nth-child(2),
.wrapper .amounttdetails > div:nth-child(2) div:nth-child(2),
.wrapper .amounttdetails > div:nth-child(3) div:nth-child(2),
.wrapper .amounttdetails > div:nth-child(4) div:nth-child(2) {
  width: 20%;
  padding: 0;
  margin: 0;
}

.wrapper .footer {
  font-weight: 800;
  position: absolute;
  bottom: 0.6%;
  left: 50%;
  transform: translateX(-50%);
}

@media print {
  .wrapper {
    /* width: 3.75in;
    height: 4in; */
    /* a4 size 8.3x11.7 */
    width: 4.1in;
    height: 5.7in;
    border-right: 1px dashed black;
    border-left: 1px dashed black;
  }
}
