@import "../../../../../customer-app/common-css/Lato-font.css";
@import "../../../../../customer-app/common-css/Poppins-font.css";

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --font-color1: #777777;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
  --border-color1: #ddd;
  --textbox-bg-color: #f9f9f9;
  --header-bg-color1: #333333;
  --header-bg-color2: #002933;
  --header-bg-color3: #36304a;
}

.orderinfo {
  position: absolute;
  bottom: 0;
  background-color: oldlace;
  width: 100%;
  font-family: "Poppins" sans-serif;
  opacity: 0.8;
  display: flex;
}

/* .orderinfo > div {
  display: flex;
} */

.orderinfo > div:nth-child(1),
.orderinfo > div:nth-child(2),
.orderinfo > div:nth-child(3),
.orderinfo > div:nth-child(4) {
  width: 33%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.orderinfo > div:nth-child(1) > div:nth-child(1),
.orderinfo > div:nth-child(1) > div:nth-child(3),
.orderinfo > div:nth-child(1) > div:nth-child(5),
.orderinfo > div:nth-child(2) > div:nth-child(1),
.orderinfo > div:nth-child(2) > div:nth-child(3),
.orderinfo > div:nth-child(2) > div:nth-child(5),
.orderinfo > div:nth-child(3) > div:nth-child(1),
.orderinfo > div:nth-child(3) > div:nth-child(3),
.orderinfo > div:nth-child(3) > div:nth-child(5),
.orderinfo > div:nth-child(4) > div:nth-child(1),
.orderinfo > div:nth-child(4) > div:nth-child(3),
.orderinfo > div:nth-child(4) > div:nth-child(5) {
  color: maroon;
  font-weight: 800;
  width: 100%;
  width: 80%;
  text-align: right;
}

.orderinfo > div:nth-child(1) > div:nth-child(2),
.orderinfo > div:nth-child(1) > div:nth-child(4),
.orderinfo > div:nth-child(1) > div:nth-child(6),
.orderinfo > div:nth-child(2) > div:nth-child(2),
.orderinfo > div:nth-child(2) > div:nth-child(4),
.orderinfo > div:nth-child(2) > div:nth-child(6),
.orderinfo > div:nth-child(3) > div:nth-child(2),
.orderinfo > div:nth-child(3) > div:nth-child(4),
.orderinfo > div:nth-child(3) > div:nth-child(6),
.orderinfo > div:nth-child(4) > div:nth-child(2),
.orderinfo > div:nth-child(4) > div:nth-child(4),
.orderinfo > div:nth-child(4) > div:nth-child(6) {
  font-weight: 600;
  color: black;
  width: 20%;
  padding-left: 10px;
}
