:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}

.supplier-criteria-wrapper {
}

.supplier-criteria-wrapper > div:nth-child(1) {
  text-align: right;
}
.supplier-criteria-wrapper .wrapper {
  display: flex;
  height: 12%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 0 2% 0;
}

.supplier-criteria-wrapper > div:nth-child(1) button {
  width: 15%;
  cursor: pointer;
  margin: 10px 10px 4px 0;
}

.supplier-criteria-wrapper .wrapper div {
  /* display: inline-block; */
  display: flex;
  flex-direction: column;
  /* margin-left: 10px; */
  align-items: flex-start;
}

.supplier-criteria-wrapper .wrapper > div div {
  display: flex;
  flex-direction: row;
  padding-right: 1em;
}
.supplier-criteria-wrapper .wrapper .checkbox {
  margin-top: 2px;
  width: 30px;
  height: 18px;
}

.supplier-criteria-wrapper .wrapper div span {
  font-size: 1.1em;
  color: var(--bluish-font-color);
  font-weight: 400;
  margin-bottom: 1%;
}

.supplier-criteria-wrapper .wrapper button {
  position: relative;
  margin: 0;
  width: auto;
  background-color: transparent;
}
.supplier-criteria-wrapper .wrapper .button {
  position: absolute;
  bottom: 3px;
  cursor: pointer;
  margin-top: 10px;
  width: 30px;
  height: 30px;
  fill: black;
}

.dropdown {
  width: 15em;
  height: 22px;
}
