.navigation-wrapper {
  display: flex;
  font-family: sans-serif;
  background-color: #fff;
  box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.09);
  /* position: relative; */
  height: 100%;
  padding: 0;
}

.logo-div {
  height: 100%;
  width: 15%;
  align-items: center;
  display: flex;
  padding-left: 1%;
}

.logo-div img {
  width: 150px;
  height: 40px;
}

.userdetails-div {
  width: 75%;
  height: 100%;
  text-align: right;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.userdetails-div strong {
  font-size: 20px;
}

.time-cell {
  font-weight: 500;
}

.user-pic-setting-wrapper {
  width: 10%;
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-box {
  width: 2.8rem;
  height: 2.8rem;
  cursor: pointer;
  color: gray;
  position: relative;
}

.profile-setting {
  width: 10rem;
  height: 8rem;
  display: flex;
  flex-direction: column;
  gap: 14px;
  position: absolute;
  right: 3.8%;
  top: 7.5%;
  margin: 0;
  padding: 20px 0 0 10px;
  box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.profile-setting li {
  list-style: none;
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  margin-left: 10px;
}
