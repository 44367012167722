@import "../../common-css/Lato-font.css";
@import "../../common-css/Poppins-font.css";

:root {
  --theme-color: #ee4949;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  animation: slidedown 300ms ease-out forwards;
  width: 500px;
  padding: 4% 10% 4% 10%;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 14%;
  text-align: left;
}

.close {
  cursor: pointer;
  position: absolute;
  right: -32px;
  top: -32px;
  color: white;
  height: 35px;
  width: 35px;
  z-index: 32;
}

.logintext {
  margin-bottom: 5%;
  color: var(--theme-color);
  font-size: 2em;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.login-wrapper input {
  height: 46px;
  margin-top: 1%;
  border-radius: 4px;
  border-style: none;
  background-color: #f9f9f9;
  border: 0.4px solid #ddd;
  font-weight: 400;
  font-size: 17px;
  color: var(--font-color);
  padding-left: 4%;
}

.emailtext,
.pwdtext {
  font-weight: 700;
  font-size: 17px;
  color: var(--bluish-font-color);
}

.login-wrapper input:focus {
  border-style: none;
  border: 1px solid var(--theme-color);
}

button {
  height: 50px;
  background-color: var(--theme-color);
  border-style: none;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  margin-top: 5%;
  border-radius: 4px;
  font-family: "Lato", sans-serif;
}

.error {
  color: #ff4c4c;
  font-weight: 400;
  margin-bottom: 4%;
  text-align: right;
}

@keyframes slidedown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
