@import "../customer-app/common-css/Lato-font.css";
@import "../customer-app/common-css/Poppins-font.css";

:root {
  --theme-color: #ee4949;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
}

.labelinput-wrapper {
  display: flex;
  flex-direction: column;
  font-family: "Lato", sans-serif;
  text-align: left;
}

.names {
  font-weight: 600;
  font-size: 17px;
  color: var(--bluish-font-color);
  text-align: left;
}
.names:hover {
  background-color: transparent;
}

.inputs {
  height: 46px;
  margin-top: 1%;
  margin-bottom: 4%;
  border-radius: 4px;
  border-style: none;
  background-color: #f9f9f9;
  border: 0.4px solid #ddd;
  font-weight: 400;
  font-size: 17px;
  color: var(--font-color);
  padding-left: 1%;
}

.inputs:focus {
  border-width: 0.5px;
  outline-color: #ee4949;
}
