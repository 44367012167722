.maindiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0 1rem 0;
  gap: 1.5rem;
  background-color: lightgray;
}

.innerdiv {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.paddingDropdown {
  padding: 5px;
  display: inline-block;
}

.height {
  height: 5rem;
}

.maindiv svg {
  height: 2rem;
}

.buttondiv {
  align-items: flex-end;
}

.buttondiv button {
  width: 12rem;
  height: 3rem;
  background-color: #ee4949;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 400;
}

.submitDiv {
  border: none;
  background-color: transparent;
  height: 0;
}

.buttondiv button:hover {
  background-color: #ec6e6e;
}
