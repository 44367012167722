.search-form {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: relative; */
}
.search-button-wrapper {
  height: 66%;
  width: 80%;
  display: flex;
  align-items: center;
  border: 2px solid #ddd;
  border-radius: 6px;
}

.input {
  width: 92%;
  height: 100%;
  font-size: medium;
  margin: 0;

  /* border-style: none; */
  border: none;
}

.input:focus {
  border: none;
}

.searchbutton {
  height: 100%;
  width: 60px;
  background-color: #f9f9f9;
  padding: 14px;
  cursor: pointer;
  border-radius: 5px;
}

@media only screen and (max-width: 500px) {
  .search-form button {
    width: 20%;
    font-size: 4vw;
  }
  .search-button-wrapper {
    height: 100%;
  }
}
