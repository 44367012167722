@import "../../../../../customer-app/common-css/Lato-font.css";
@import "../../../../../customer-app/common-css/Poppins-font.css";

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}

.wrapper {
  height: 100%;
  width: 84%;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
}

.productsearch {
  display: flex;
}

/* TAB NAME,BARCCODE */
.searchoption {
  display: flex;
  color: var(--bluish-font-color);
  font-weight: 500;
  width: 25%;
}
.searchoption div {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ddd;
}
/* END */

.productsearch .inputsearch {
  position: relative;
  width: 51%;
  height: 32px;
}

.productsearch .inputsearch input[type="search"] {
  margin: 0;
  height: 100%;
  font-size: 1em;
}

.productsearch .deliveryDatewrapper,
.productsearch .otherdiv {
  width: 12%;
  height: 32px;
}

.productsearch .deliveryDate,
.productsearch .deliveryCharge {
  width: 100%;
  height: 32px;
  margin: 0;
  padding: 0;
  font-size: 0.9em;
}
.productsearch .deliveryDate {
  padding-left: 12px;
}

/* .productsearch .otherdiv .deliveryCharge{
  width: 100%;
  font-size: .9rem;
} */

.product-wrapper {
  overflow: auto;
  overflow-x: hidden;
  height: 92%;
  box-sizing: border-box;
}

/* RESULT POPUP */
.searchresult {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 90;
  font-weight: 600;
  background-color: white;
  border: 1px solid var(--border-color);
}

.backgroundcolor {
  color: var(--white-color);
  background-color: var(--theme-color);
  border: groove;
}

.header {
  width: 100%;
  height: 10%;
  display: flex;
  position: sticky;
  top: 0;
  font-weight: 600;
  background-color: gray;
  color: var(--white-color);
  z-index: 80;
}

.header div {
  border-bottom: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  border-collapse: collapse;
  display: flex;
  justify-content: center;
  align-items: center;
}

.productrow {
  display: flex;
  width: 100%;
  position: relative;
}
.productrow div {
  border-bottom: 0.5px solid var(--border-color);
  border-right: 0.5px solid var(--border-color);
  font-weight: 600;
  color: var(--font-color);
  display: flex;
  justify-content: center;
  cursor: pointer;
}
/* .productrow:hover {
  background-color: lightgray;
} */

.header div:nth-child(1),
.productrow div:nth-child(1) {
  width: 5%;
}
.header div:nth-child(2),
.productrow div:nth-child(2) {
  width: 29%;
}

.productrow div:nth-child(2) {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.header div:nth-child(3),
.productrow div:nth-child(3) {
  width: 8%;
}

.productrow div:nth-child(3) input {
  border: none;
  width: 100%;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
}

.header div:nth-child(4),
.productrow div:nth-child(4) {
  width: 8%;
}

.header div:nth-child(5),
.productrow div:nth-child(5) {
  width: 7%;
}

.header div:nth-child(6),
.productrow div:nth-child(6) {
  width: 10%;
}
.header div:nth-child(7),
.productrow div:nth-child(7) {
  width: 5%;
}
.header div:nth-child(8),
.productrow div:nth-child(8) {
  width: 10%;
}
.header div:nth-child(9),
.productrow div:nth-child(9) {
  width: 9%;
}

.header div:nth-child(10),
.productrow div:nth-child(10) {
  width: 10%;
}

.productrow div:nth-child(10) input {
  border: none;
  width: 100%;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
}

.subtotal-wrapper {
  color: var(--font-color);
  font-weight: 500;
  font-size: 1em;
  display: flex;
  flex-direction: column;
  /* background-color: yellow; */
  align-items: flex-end;
  position: sticky;
  bottom: 0;
  background-color: var(--white-color);
  padding-right: 4%;
}

.subtotal-wrapper > div {
  width: 60%;
  /* background-color: brown; */
  text-align: right;
}

.subtotal-wrapper > div span:nth-child(2) {
  width: 15%;
  display: inline-block;
}

.subtotal-wrapper > div:nth-child(1) {
  font-weight: bold;
}

.subtotal-wrapper > div:nth-child(4) {
  font-size: 1.3em;
  font-weight: bold;
  color: var(--font-color);
}

.subtotal-wrapper > div:nth-child(4) span:nth-child(2) {
  color: var(--theme-color);
}

.rowdeleteicon {
  position: absolute;
  right: 0;
  cursor: pointer;
  width: 20px;
  color: var(--theme-color-shade1);
  height: 100%;
}

/*********** PRINTING START ***********/
@media print {
  .product-wrapper,
  .subtotal-wrapper {
    width: 3.75in;
  }

  /* .product-wrapper {
    height: 2in;
  } */
  .product-wrapper {
    padding-right: 0.009in;
  }

  .subtotal-wrapper {
    padding-right: 18px;
  }

  .header {
    background-color: #fff;
    color: var(--header-color);
    font-size: 0.7em;
  }
  .header div {
    border: none;

    justify-content: left;
  }
  .productsearch {
    display: none;
  }
  .productrow div {
    border: none;
    display: flex;
    justify-content: flex-start;
    font-weight: 500;
    color: var(--header-color);
  }

  .header div:nth-child(1),
  .productrow div:nth-child(1) {
    width: 7%;
  }
  .header div:nth-child(2),
  .productrow div:nth-child(2) {
    width: 19%;
  }

  .header div:nth-child(3),
  .productrow div:nth-child(3) {
    width: 7%;
  }

  .productrow div:nth-child(3) input {
    text-align: left;
  }

  .header div:nth-child(4),
  .productrow div:nth-child(4) {
    width: 12%;
  }

  .header div:nth-child(5),
  .productrow div:nth-child(5) {
    width: 16%;
  }

  .header div:nth-child(6),
  .productrow div:nth-child(6) {
    width: 7%;
  }

  .header div:nth-child(7),
  .productrow div:nth-child(7) {
    width: 15%;
  }
  .header div:nth-child(8),
  .productrow div:nth-child(8) {
    width: 15%;
  }

  .subtotal-wrapper > div {
    width: 100%;
    font-size: 0.9em;
    color: var(--bluish-font-color);
    text-align: right;
    padding: 0;
  }
  .subtotal-wrapper > div:nth-child(1) {
    font-weight: inherit;
  }

  .subtotal-wrapper > div span:nth-child(2) {
    width: 30%;
    display: inline-block;
  }

  .subtotal-wrapper > div:nth-child(4) {
    font-size: inherit;
    font-weight: bold;
    color: var(--bluish-font-color);
  }

  .subtotal-wrapper > div:nth-child(4) span:nth-child(2) {
    color: var(--bluish-font-color);
  }

  /* .productrow {
    width: 100vw;
  } */
}

/*********** PRINTING END ***********/
