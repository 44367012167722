.footer-wrapper {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 10px;
}

.footer-wrapper h4 {
  font-size: 16px;
}

.copyright {
  border-top: 1px solid #ddd;
  color: #777777;
}

.copyright p {
  text-align: center;
  font-size: 0.9rem;
  font-weight: 400;
  padding: 30px 0 20px;
}

.icon-contact-information {
  margin-right: 6px;
  position: relative;
  top: 2px;
}

.ull {
  list-style: none;
  padding-left: 0;
  padding-bottom: 10px;
  font-weight: 400;
  color: #777;
}

p {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 700;
}

@media only screen and (max-width: 400px) {
  /* .footer-wrapper {
    text-align: center;
  } */
}
