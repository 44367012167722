@import "../../common-css/Lato-font.css";
@import "../../common-css/Poppins-font.css";

:root {
  --theme-color: #ee4949;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
}

.signuptext,
.forgetpwdtext {
  font-weight: 400;
  color: var(--font-color);
  text-align: center;
}

.forgetpwdtext {
  text-align: right;
  font-weight: 700;
}
.forgetpwdtext:hover {
  text-decoration: underline;
  cursor: pointer;
}

.signuptext {
  margin-top: 4%;
  margin-bottom: 2%;
}

.signuptext u {
  color: var(--theme-color);
  font-weight: 600;
  cursor: pointer;
}
