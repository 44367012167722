@import url("https://fonts.googleapis.com/css2?family=Gowun+Batang:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap");

/* .wrapper {
  font-family: "Lato", sans-serif;
  overflow: auto;
  height: 100%;
  background-color: #fff;
  position: relative;
  transition: 6s;
} */

.wrapper {
  display: flex;
  height: 100%;
  width: 100%;
}

.paragraph-div1 {
  width: 58%;
  font-weight: bold;

  display: block;
  color: #777;
  margin-left: 10%;
  font-weight: 700;
  /* padding-top: 5%; */
}

/* 
.para11 {
  text-align: center;
  font-size: 36px;
  margin: 0;
  margin-top: 56px;
  font-weight: 700;
}
.para12 {
  text-align: center;

  font-size: 28px;
  padding: 0px;
  margin: 0;

  margin-top: 10px;
  margin-bottom: 6px;
} */

.para1 {
  text-align: center;
  font-size: 36px;
  margin: 0;
  margin-top: 56px;
  color: #222;
  font-weight: 700;
}
.para2,
.para3 {
  text-align: center;
  display: inline-block;
  font-size: 28px;
  padding: 0px;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 6px;
  font-family: "Gowun Batang", serif;
}

.para3 {
  display: inline-block;
}

/* .leftarrow,
.rightarrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 76px;
  height: 80px;
  padding: 0;
  background-color: #f3f3f3;
  opacity: 0.7;
  cursor: pointer;
}

.rightarrow {
  right: 0;
} */

.img1,
.img2 {
  /* float: right; */
  width: 42%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 500px) {
  .paragraph-div1 {
    margin-top: 38px;
    line-height: 22px;

    opacity: 0.7;
    margin-left: 0;
    padding-top: 0;
  }

  .para1 {
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 5%;
    margin-left: 12px;
    text-align: left;
    font-weight: 900;
    color: black;
  }
  .para2,
  .para3 {
    display: block;
    text-align: left;
    font-size: 18px;
    color: black;

    /* border: 1px solid black; */
    margin: 0;
    margin-left: 30px;
  }

  .para3 {
    margin-left: 42px;
    margin-top: 0;
  }

  .img1,
  .img2 {
    height: 180px;
    width: 45%;
    object-fit: cover;
    opacity: 1;
  }
}
