@import "../../../../../customer-app/common-css/Poppins-font.css";

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --font-color1: #777777;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
  --border-color1: #ddd;
  --textbox-bg-color: #f9f9f9;
  --header-bg-color1: #333333;
  --header-bg-color2: #002933;
  --header-bg-color3: #36304a;
}

.editwrapper {
  z-index: 50;
}

/* .livestock-edit-wrapper {
  display: flex;
  flex-direction: column;
  line-height: 2;
  margin: -2px;
  position: relative;
} */

/* .livestock-edit-wrapper > div {
  height: 3vh;
} */

/* .livestock-edit-wrapper > div > span:nth-child(1) {
  width: 35%;
  display: inline-block;
  font-size: 14px;
}

.livestock-edit-wrapper > div > span:nth-child(2),
.livestock-edit-wrapper > div .textbox {
  width: 65%;
  display: inline-block;
  font-size: 12px;
} */

/* .livestock-edit-wrapper > div .textbox {
  border: 1px solid var(--border-color1);
} */

/* .closebutton {
  position: absolute;
  top: 0px;
  right: -8px;
  color: var(--font-color);
  cursor: pointer;
  width: 15%;
  height: 15%;
} */

/* .button-wrapper {
  display: flex;
  flex-direction: row;
  background-color: yellow;
  align-items: flex-end;
}

.livestock-edit-wrapper .button-wrapper button {
  width: fit-content;
  border-radius: 0;
  min-height: 50%;
} */
