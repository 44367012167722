@import "../../../common-css/Lato-font.css";
@import "../../../common-css/Poppins-font.css";

:root {
  --theme-color: #ee4949;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --white-color: #fff;
}

.main-wrapper {
  box-sizing: border-box;
  background-color: var(--white-color);
  width: 92%;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 1%;
  margin-left: 4%;
  margin-right: 4%;
  color: #77798c;
  text-align: left;
  box-shadow: 0.6px 0.6px 2px #ddd;
}

.main-wrapper div {
  text-align: right;
}

.buttons {
  margin-top: 3%;
  width: 30%;
  font-size: 1.5em;
}

.termscondition input {
  height: fit-content;
  margin-right: 0.5%;
}
.termscondition span {
  font-weight: 300;
}
.termscondition u {
  color: var(--theme-color);
  font-weight: 400;
  cursor: pointer;
}

.city-wrapper {
  position: relative;
  /* border: 1px solid black; */
}
.citylist {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 40;
  top: 72px;
  left: 3px;
  background-color: #f9f9f9;
  width: 50%;
  min-height: fit-content;
  max-height: 100px;
  overflow: auto;
  border: 1px solid #ddd;
  border-top: none;
  box-shadow: 1px 1px 0 #ddd;
}
.citylist span {
  text-align: left;
  padding-left: 8px;
  cursor: pointer;
  padding-top: 8px;
  font-weight: 400;
}

.citylist span:hover {
  background-color: lightgray;
}
