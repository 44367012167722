:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}

.wrapper {
  display: flex;
  height: 12%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 0 2% 0;
  margin: 0;
}

.wrapper div {
  /* display: inline-block; */
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  align-items: flex-start;
}

.wrapper button {
  position: relative;
  margin: 0;
  width: auto;
  background-color: transparent;
}
.wrapper .button {
  position: absolute;
  bottom: 3px;
  cursor: pointer;
  margin-top: 10px;
  width: 30px;
  height: 30px;
  fill: black;
}

.catg,
.subcatg,
.product,
.choice {
  height: 22px;
}

.catg {
  width: 16em;
}
.subcatg {
  width: 15em;
}
.product {
  width: 15em;
}
.choice {
  width: 10em;
}
