.header {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  min-height: 10%;
  /* border: 1px solid black; */
  position: sticky;
  top: 0;
  color: var(--bluish-font-color);
  font-weight: 600;
  font-size: 1.8em;
  padding-left: 2%;
}

.header > span {
  font-weight: 500;
  font-family: "Lato", sans-serif;
  color: #777;
  margin-left: 4%;
  font-size: 0.7em;
}

.header p {
  cursor: pointer;
  display: inline;
}
