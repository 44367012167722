.buttonControl1 {
  width: 12rem;
  height: 3rem;
  background-color: #ee4949;
  border: none;
  color: white;
  font-weight: 400;
  font-size: 1.3rem;
  cursor: pointer;
  margin: 0;
}
