.wrapper {
  height: 100%;
  position: relative;
}

.forwardbtn {
  background-color: green;
  cursor: pointer;
  width: 12em;
  margin-top: 0;
  border-radius: "5px";
  position: absolute;
  top: 0;
  font-size: medium;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 2px 2px 4px lightgreen;
}
