.spinnerContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.82);
  /* display: flex; */
  /* align-items: center; */
  z-index: 9999;
}

.spinner {
  z-index: 10000;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(50%, -50%);

  /* border: 1px solid rgb(55, 53, 53); */
}

.tag {
  text-align: center;
  font-size: 2rem;
  color: green;
}
