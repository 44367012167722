@import "../../../../customer-app/common-css/Lato-font.css";
@import "../../../../customer-app/common-css/Poppins-font.css";

:root {
  --theme-color: #ee4949;
  --theme-color-shade1: #f05c5c;
  --theme-color-shade2: #f48a8a;
  --font-color: #77798c;
  --bluish-font-color: #161f6a;
  --header-color: #0d1136;
  --white-color: #fff;
  --border-color: #e8e8e8;
}

.wrapper {
  height: 100%;
  width: 16%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #f9f9f9;
  font-weight: 600;
}

.wrapper > div {
  display: flex;
  flex-wrap: wrap;
  /* height: 33.33%; */
  cursor: pointer;
}
.wrapper > div:nth-child(1) {
  padding: 2% 0 0 2%;
  height: 20%;
}

.wrapper > div:nth-child(2) {
  padding: 2% 0 0 2%;
  /* margin-top: 1.665%; */
  height: 37.5%;
}

.wrapper > div:nth-child(3) {
  padding: 2% 0 0 2%;
  /* margin-top: 1.665%; */
  height: 37.5%;
}

.wrapper > div:nth-child(1) > div,
.wrapper > div:nth-child(2) > div,
.wrapper > div:nth-child(3) > div {
  width: 49%;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #187498;
  color: var(--white-color);
}

.wrapper > div:nth-child(1) > div:hover,
.wrapper > div:nth-child(2) > div:hover,
.wrapper > div:nth-child(3) > div:hover {
  background-color: var(--theme-color-shade1);
  border: 1px solid var(--theme-color-shade1);
}

/* .wrapper > div:nth-child(1) > div:nth-child(1) {
  margin-right: 1%;
} */

.wrapper > div:nth-child(2) > div {
  height: 33.33%;
}

.wrapper > div:nth-child(3) > div {
  height: 33.33%;
}

/* .wrapper > div:nth-child(3) > div:nth-child(1),
.wrapper > div:nth-child(3) > div:nth-child(3),
.wrapper > div:nth-child(3) > div:nth-child(5),
.wrapper > div:nth-child(2) > div:nth-child(1),
.wrapper > div:nth-child(2) > div:nth-child(3),
.wrapper > div:nth-child(2) > div:nth-child(5) {
  margin-right: 1%;
} */
