#header-div {
  height: 5em;
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  background-color: #fff;
  box-shadow: 1px 1px 1px #efefef;
  position: sticky;
  top: 0;
  z-index: 50;
}

#left-aside-div {
  height: 40%;
  width: 78%;
  float: left;
  background-color: #f9f9f9;
  /* border: 1px solid black; */
  margin-right: 3%;
  margin-left: 2%;
  margin-top: 18px;
  box-shadow: 1px 1px 1px #efefef;
}

#content-div {
  height: 40%;
  width: 15%;
  float: left;
  margin-top: 18px;
  /* box-shadow: 1px 1px 1px #efefef; */
}

#right-aside-div {
  width: 100%;
  float: left;
  padding-left: 2%;
  padding-right: 2%;
  margin-top: 22px;
  margin-bottom: 22px;
  background-color: #fff;
  height: fit-content;
  box-shadow: 2px 2px 2px #efefef;
  z-index: 15;
}

#footer-component-div {
  height: fit-content;
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  float: left;
  background-color: #fff;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 500px) {
  #header-div {
    height: 4.5em;
    padding-left: 0.6%;
    padding-right: 0.6%;
  }

  #left-aside-div {
    width: 100%;
    height: fit-content;
    margin: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  #content-div {
    height: fit-content;
    width: 100%;
  }

  #right-aside-div {
    width: 100%;
    height: fit-content;
  }
}
