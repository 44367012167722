.MenuIconCard-wrapper {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
}

.MenuIconCard {
  box-shadow: 3px 3px 1px #efefef;
  background-color: #ffffff;
  width: 100%;
  height: 44%;
  cursor: pointer;
  overflow: auto;
  margin-bottom: 6%;
  border: none;
  border-radius: 6px;
}

.MenuIconCard-border {
  border: 2px solid black;
}

@media only screen and (max-width: 500px) {
  .MenuIconCard-wrapper {
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }
  .MenuIconCard {
    width: 40%;
    margin-bottom: 0;
  }
}
