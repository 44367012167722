@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
.aside-container {
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 92vh;
  background-color: #0d1136;
  opacity: 0.8;
  font-family: "Lato", sans-serif;
}

.dashBoard,
.category,
.product,
.purchase,
.pricing,
.customer,
.order,
.store,
.reports,
.billing {
  /* color: #fff; */
  color: #ffffffcc;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  border-bottom: 1px solid #ffffff1a;
  margin-bottom: 1px;
  margin-left: 2rem;
  margin-right: 2rem;
}

.dashBoard {
  margin-top: 20px;
}

.dashBoard p,
.category p,
.product p,
.purchase p,
.pricing p,
.customer p,
.order p,
.store p,
.reports p,
.billing p {
  text-align: left;
}

ul {
  /* padding: 0.6rem 0rem 0.6rem 3.1rem; */
  margin: 0px;
  /* display: flex;
  flex-direction: column; */
  /* margin: 0 0 0 1.4rem; */
}

a + ul li {
  font-size: 16px;
  font-weight: 400;
  padding-top: 0.6rem;
  padding-bottom: 1rem;
  margin-right: 2rem;
  margin-left: 3rem;
  list-style: none;
  cursor: pointer;
  border-bottom: 1px solid #ffffff1a;
  color: #ffffffcc;
}
