.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1%;
  padding-bottom: 1%;
}

.customer-wrapper {
  width: 100%;
  height: 32%;
  background-color: #fff;
}

.prod-actionmenus-wrapper {
  width: 100%;
  height: 68%;
  display: flex;
  padding-top: 0.8%;
}
