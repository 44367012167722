@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
.itemcard-wrapper {
  width: 260px;
  height: 340px;
  font-family: "Lato", sans-serif;
  display: flex;
  flex-direction: column;
  /* border: 1px solid black; */
  box-shadow: 2px 2px 5px #efefef;
  background-color: #fff;
  margin-left: 18px;
  margin-bottom: 18px;
  color: rgb(153, 153, 153);
  border-radius: 10px;
}

.itemcard-wrapper:hover {
  border: 1px solid #f9c8c8;
  cursor: pointer;
}

.item-discount {
  height: 7%;
  width: 100%;
  /* border: 1px solid black; */
  float: right;
  color: #ee4949;
  text-align: right;
  font-weight: 600;
}

.name p {
  font-size: 18px;
  margin-bottom: 2px;
  /* border: 1px solid black; */
}

.image-div {
  box-sizing: content-box;
  text-align: center;
  /* height: 160px; */
  height: 46%;
  margin-top: 2%;
}

img {
  width: 200px;
  height: 100%;
}

.itemProperties-combined-wrapper {
  padding-left: 10px;
  padding-right: 10px;
  height: 43%;
  margin-top: 2%;
}

/*item name wrapper*/
.hindiName {
  margin-top: 0.5%;
}

/*price wrapper*/

.price-wrapper {
  margin-top: 2%;
}
.mrp-text {
  font-weight: 600;
}
.mrp-value {
  color: #0d1136;
  font-weight: 600;
}

.unit-wrapper {
  margin-top: 5%;
  /* border: 1px solid black; */
  display: flex;
}

.unit-inner-wrapper {
  width: 60%;
}

.primaryUnit,
.secondaryUnit {
  background-color: #ee4949;
  display: block;
  border-radius: 3px;
  height: 25px;
  text-align: center;
  color: white;
  font-weight: 400;
  cursor: pointer;
  /* padding-left: 8px;
  padding-right: 8px; */
  cursor: pointer;
  float: left;
  width: 100%;
}

.secondaryUnit {
  margin-top: 2%;
}

.minus,
.plus {
  width: 20%;
  height: 100%;
}

.minus:hover,
.plus:hover {
  background-color: #f16d6d;
}

.minus {
  float: left;
}

.plus {
  float: right;
}

.add {
  width: 100%;
  display: inline-block;
}

.quantity-selected-wrapper {
  width: 40%;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  /* margin-left: 6px; */
  box-shadow: 2px 2px 5px #efefef;
}

.quantity-selected-wrapper span {
  color: #222;
}

.dummystyle {
  background-color: transparent;
}
